import React from 'react';
// import { ExclamationCircleFilled } from '@ant-design/icons';
import axiosInstance from '../../Services/axiosCommon';
import { useToast } from '../Others/ToastServices';
import { useSocket } from '../../Context/SocketContext';
// import useNotification from './useNotification';
// import { Modal, notification } from 'antd';

export const useDeleteData = (fecher) => {
    // const [api, contextHolder] = notification.useNotification();
    // const { confirm } = Modal;
    const toast = useToast();
    const {sendProfileNotification} = useSocket();

    const handle = (url,state) => {
    // confirm({
    //     title: `Do you want to delete ${removevalue}?`,
    //     centered: true,
    //     icon: <ExclamationCircleFilled />,
    //     content: `${removevalue || 'undefined'} will be removed from the ${module} list`,
    //     okText: 'Yes',
    //     cancelText: 'No',
    //     onOk() {
                axiosInstance.delete(url)
                    .then(res => {
                        if (res?.data.status === true) {
                          if (res.data.notification!==null || res.data.notification!==undefined || res.data.notification!==""){
                            sendProfileNotification(res.data.notification);
                          }
                            toast.open({
                                type:"success",
                                message:"Success",
                                description: res.data?.msg || "Deleted Successfully."
                              })
                            state(false);
                            fecher();
                        }
                        else if(res.data?.status === false && res.data?.error){
                            toast.open({
                              type:"error",
                              message:"Error",
                              description: res.data.error || "Network error."
                            })
                          } 
                        else {
                            toast.open({
                                type:"warning",
                                message:"Warning",
                                description: res.data?.msg || "Warning."
                              })
                        }
        
                    }).catch(
                        error => {
                            console.log("error",error);
                            toast.open({
                                type:"error",
                                message:"Error",
                                description:  "Network error."
                              })
                        }
                    );
        }
    // });};

    return ( { handle } )
}
