import React from 'react';
import { TextMediumdS, TextMediumSM } from '../../../../../Components/Text/MediumText';
import { TextMediumXS } from '../../../../../Components/Text/MediumText';
import UseGetDashBoardData from '../../../../../Components/Hooks/useDashboardData';

function TopProductsBySalesVolumeProDash() {
  const { data, loading, error } = UseGetDashBoardData('/dashboard/top-products-by-sales-volume');

  const dataDummy = data?.top_products_by_sales_volume?.map(product => ({
    product: product.product_name,
    quantity: product.quantity
  })) || [];

  return (
    <div className="col-span-2 bg-white h-[40vh] w-full p-4  rounded-lg drop-shadow-lg mb-4">
   <TextMediumSM content={"Top Products by Sales Volume"} color='text-[#4F4768]' />
   <div className="border-t border-[1px] border-slate-200/60 border-solid w-full mt-2"></div>
     
      {loading ? (
        <div className="flex justify-center items-center h-full">
          <div className="animate-pulse space-y-4 w-full">
            <div className="h-4 bg-slate-200 rounded"></div>
            <div className="h-4 bg-slate-200 rounded"></div>
            <div className="h-4 bg-slate-200 rounded"></div>
            <div className="h-4 bg-slate-200 rounded"></div>
          </div>
        </div>
      ) : error ? (
        <div className="flex justify-center items-center h-full">
          <p>Error loading data</p>
        </div>
      ) : (
        <div className="w-full h-[89%] overflow-y-scroll pb-4">
          <table className="w-full">
            <thead className="sticky top-0 bg-white shadow-sm">
              <tr>
                <th className="text-left px-2 py-1"><TextMediumSM content="Product" /></th>
                <th className="text-center px-2 py-1"><TextMediumSM content="Quantity" /></th>
              </tr>
            </thead>
            <tbody>
              {dataDummy.map((value, index) => (
                <tr className="odd:bg-white even:bg-slate-50  " key={index}>
                  <td className="px-2 py-2"><TextMediumdS content={value.product || "N/A"} /></td>
                  <td className="px-2 py-2 text-center"><TextMediumdS content={value.quantity || "N/A"} /></td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

export default TopProductsBySalesVolumeProDash;
