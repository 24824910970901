import { useState } from "react";
import ToastContext from "./ToastServices";
import CloseIcon from "../../assets/icons/Close";
import { TextMediumBase, TextMediumSM } from "../Text/MediumText";
import { TextSM } from "../Text/NormalText";
import Success from "../../assets/icons/Success";
import Warning from "../../assets/icons/Warning";
import Error from "../../assets/icons/Error";
import BellIcon from "../Icons/BellIcon";
import moment from "moment";
import NuDate from "../Utils/NuDate";

export default function ToastProvider({ children }) {
  const [toasts, setToasts] = useState([]);
  const [showNotification,setShowNotification] = useState(true);

  const open = (component, timeout = 5000,notification=false) => {
    const id = Date.now();

    setToasts((toasts) => [...toasts, { id, component }]);
    setShowNotification(notification)
    setTimeout(() => close(id), timeout);
  };

  const close = (id) => {
    setToasts((toasts) => toasts.filter((toast) => toast.id !== id));
  };

  return (
    <ToastContext.Provider value={{ open, close }}>
      {children}
      <div className={`space-y-2 absolute ${toasts.length >5 ? "min-w-80 max-w-96":""} ${showNotification == true ?"top-4 left-1/2 transform -translate-x-1/2":"bottom-4 right-4"}  z-[999]`}>
        {toasts.map(({ id, component },index) => {
          // console.log(component);
          return (
            <div
              key={id}
              className={` min-w-80 max-w-96 bg-white duration-300 -translate-x-9  transition ease-in-out rounded-lg shadow-[-10px_-10px_30px_4px_rgba(0,0,0,0.1),_10px_10px_30px_4px_rgba(45,78,255,0.15)] px-4 py-3`}
              style={{
                zIndex: toasts.length >5 ? index : 999,
                position: toasts.length >5 ? "absolute" : "relative",
              }}
            >
              <div className=" w-full flex justify-between items-center gap-3">
                <div className=" w-12 h-12 flex justify-center items-center  rounded-full overflow-hidden">
                    {
                        (component?.type =="success" || component?.type =="Success") && <Success/>
                    }
                    {
                        (component?.type =="error" || component?.type =="Error") && <Error/>
                    }
                    {
                        (component?.type =="warning" || component?.type =="Warning") && <Warning/>
                    }
                                        {
                        (component?.type =="notification" || component?.type =="Notification") && <div className="w-full h-full bg-slate-100 flex justify-center items-center"><BellIcon/></div>
                    }
                </div>
                <div className=" min-w-[80%] flex-1">
                  {
                    (component?.type =="notification" || component?.type =="Notification") == true ? 
                    <>
                    <div className=" flex justify-between items-start">
                    <div className=" w-[85%]">
                      <TextMediumBase
                        content={component?.message || ""}
                        truncate={true}
                      />
                    </div>
                    <button
                      onClick={() => close(id)}
                      className=" p-2 rounded-lg bg-gray-600/20 text-gray-800/60"
                    >
                      <CloseIcon />
                    </button>
                  </div>
                  <div className=" w-full">
                    <p className=" w-full break-words text-xs 2xl:text-sm font-normal text-wrap line-clamp-3 whitespace-normal">{component?.description||""}</p>
                  <div className=" text-[11px] font-medium flex justify-start items-center gap-3">
                  <p className="text-[10px] text-slate-700">
                         By {component?.name} {" "}
                        {moment(component?.date).format("DD-MMM-YYYY")} at{" "}
                        <span className="text-[8px] text-slate-700">
                          <NuDate
                            value={`${component?.date} ${component?.time}`}
                            format="hh:mm a"
                          />
                        </span>
                      </p>
                  </div>
                  </div> 
                    </>
                    :(
                      <>
                    <div className=" flex justify-between items-start">
                    <div className=" w-[85%]">
                      <TextMediumBase
                        content={component?.message || ""}
                        truncate={true}
                      />
                    </div>
                    <button
                      onClick={() => close(id)}
                      className=" p-2 rounded-lg bg-gray-600/20 text-gray-800/60"
                    >
                      <CloseIcon />
                    </button>
                  </div>
                  <div>
                    <TextSM content={component?.description||""} />
                  </div>   
                      </>
                    )
                  }
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </ToastContext.Provider>
  );
}


// import { useState } from "react";
// import ToastContext from "./ToastServices";
// import CloseIcon from "../../assets/icons/Close";
// import { TextMediumBase, TextMediumSM } from "../Text/MediumText";
// import { TextSM } from "../Text/NormalText";
// import Success from "../../assets/icons/Success";
// import Warning from "../../assets/icons/Warning";
// import Error from "../../assets/icons/Error";
// import BellIcon from "../Icons/BellIcon";
// import moment from "moment";
// import NuDate from "../Utils/NuDate";
// import { useNavigate } from "react-router-dom";

// export default function ToastProvider({ children }) {
//   const [toasts, setToasts] = useState([]);
//   const [showNotification,setShowNotification] = useState(true);
//   const navigate = useNavigate();

//   const open = (component, timeout = 5000,notification=false) => {
//     const id = Date.now();

//     setToasts((toasts) => [...toasts, { id, component, module: component?.module, notificationId: component?.id }]);
//     setShowNotification(notification)
//     setTimeout(() => close(id), timeout);
//   };

//   const close = (id) => {
//     setToasts((toasts) => toasts.filter((toast) => toast.id !== id));
//   };

//   const handleNotificationClick = (module, id , type) => {
//     if (type === "notification") {
//     switch (module) {
//       case "pr":
//         navigate(`/procurement/purchase-requests/view/${id}`);
//         break;
//       case "rfq":
//         navigate(`/procurement/rfq/view/${id}`);
//         break;
//       case "supplier_quotes":
//         navigate(`/procurement/rfq/view/${id}`);
//         break;
//       case "purchase_order":
//         navigate(`/procurement/purchase-order/view/${id}`);
//         break;
//       case "sales_order":
//         navigate(`/sales/sales-orders/view/${id}`);
//         break;
//       case "customer_quotes":
//         navigate(`/sales/quotes/view/${id}`);
//         break;
//       case "supplier":
//         navigate(`/procurement/suppliers/view/${id}`);
//         break;
//       default:
//         console.error("Invalid module");
//     }
//     }
//   };

//   return (
//     <ToastContext.Provider value={{ open, close }}>
//       {children}
//       <div className={`space-y-2 absolute ${toasts.length >5 ? "min-w-80 max-w-96":""} ${showNotification == true ?"top-4 left-1/2 transform -translate-x-1/2":"bottom-4 right-4"}  z-[999]`}>
//         {toasts.map(({ id, component, module, notificationId  },index) => {
//           // console.log(component);
//           return (
//             <div
//               key={id}
//               className={` min-w-80 max-w-96 bg-white duration-300 -translate-x-9  transition ease-in-out rounded-lg shadow-[-10px_-10px_30px_4px_rgba(0,0,0,0.1),_10px_10px_30px_4px_rgba(45,78,255,0.15)] px-4 py-3`}
//               style={{
//                 zIndex: toasts.length >5 ? index : 999,
//                 position: toasts.length >5 ? "absolute" : "relative",
//               }}
//             >
//               <div className=" w-full flex justify-between items-center gap-3">
//                 <div className=" w-12 h-12 flex justify-center items-center  rounded-full overflow-hidden">
//                     {
//                         (component?.type =="success" || component?.type =="Success") && <Success/>
//                     }
//                     {
//                         (component?.type =="error" || component?.type =="Error") && <Error/>
//                     }
//                     {
//                         (component?.type =="warning" || component?.type =="Warning") && <Warning/>
//                     }
//                                         {
//                         (component?.type =="notification" || component?.type =="Notification") && <div className="w-full h-full bg-slate-100 flex justify-center items-center"><BellIcon/></div>
//                     }
//                 </div>
//                 <div className=" min-w-[80%] flex-1">
//                   {
//                     (component?.type =="notification" || component?.type =="Notification") == true ? 
//                     <>
//                     <div className=" flex justify-between items-start">
//                     <div
//                       className="w-[85%] cursor-pointer"
//                       onClick={() => handleNotificationClick(component?.module, component?.id)}
//                     >
//                       <TextMediumBase
//                         content={component?.message || ""}
//                         truncate={true}
//                       />
//                     </div>
//                     <button
//                       onClick={() => close(id)}
//                       className=" p-2 rounded-lg bg-gray-600/20 text-gray-800/60"
//                     >
//                       <CloseIcon />
//                     </button>
//                   </div>
//                   <div className=" w-full">
//                     <p className=" w-full break-words text-xs 2xl:text-sm font-normal text-wrap line-clamp-3 whitespace-normal">{component?.description||""}</p>
//                   <div className=" text-[11px] font-medium flex justify-start items-center gap-3">
//                   <p className="text-[10px] text-slate-700">
//                          By {component?.name} {" "}
//                         {moment(component?.date).format("DD-MMM-YYYY")} at{" "}
//                         <span className="text-[8px] text-slate-700">
//                           <NuDate
//                             value={`${component?.date} ${component?.time}`}
//                             format="hh:mm a"
//                           />
//                         </span>
//                       </p>
//                   </div>
//                   </div> 
//                     </>
//                     :(
//                       <>
//                     <div className=" flex justify-between items-start">
//                     <div className=" w-[85%]">
//                       <TextMediumBase
//                         content={component?.message || ""}
//                         truncate={true}
//                       />
//                     </div>
//                     <button
//                       onClick={() => close(id)}
//                       className=" p-2 rounded-lg bg-gray-600/20 text-gray-800/60"
//                     >
//                       <CloseIcon />
//                     </button>
//                   </div>
//                   <div>
//                     <TextSM content={component?.description||""} />
//                   </div>   
//                       </>
//                     )
//                   }
//                 </div>
//               </div>
//             </div>
//           );
//         })}
//       </div>
//     </ToastContext.Provider>
//   );
// }

