import React, { useEffect, useState } from "react";
import { BackButton, CreateButton, EditButton } from "../../../../Components/Utils/Buttons";
import { Link, useLocation, useParams } from "react-router-dom";
import Breadcrumb from "../../../../Components/Utils/Breadcrumb";
import NuDate from "../../../../Components/Utils/NuDate";
import Modal from "../../../../Components/Utils/AttachmentView";
import { Tab, Tabs } from "../../../../Layouts/MainPage/Tabs";
import axiosInstance from "../../../../Services/axiosCommon";
import CommonComments from "../../../../Components/Utils/commonComments";
import capitalizeFirstLetter from "../../../../Components/Utils/Funcs";
import ApprovalComponent from "../../../../Components/Utils/approvalComponent";
import PRPdfOther from "../../ViewPdfTemplate/PRPdfOther";
import PDFViewer from "../../../../Components/Utils/PDFViewer";
import FilesView from "../../../../Components/Utils/FilesView";
import PDFViewWithAPI from "../../../../Components/Utils/PDFViewWithAPI";
import { GetAccess, GetNotUseRole, GetRole } from "../../../../Components/Utils/roles";

function ViewPr() {
  const { prViewId } = useParams();
  const location = useLocation();
  const prData = location?.state?.data


  const [data,setData] = useState(prData? prData:null); 
  console.log(data,"data pr");
  

  function getView() {
    axiosInstance
      .get(`/PR/${prViewId}`)
      .then((res) => {
        setData(res.data?.data);       
      })
      .catch(err=>{
        console.log("err", err);
      })
  }
  
  useEffect(() => {
    if (!data) {
      getView();
    }
  }, [prViewId]);
  
  
  const [isCommentsModalOpen, setIsCommentsModalOpen] = useState(false);
  const [showPdfComponent, setShowPdfComponent] = useState(false);
  const EditAccess = "purchase_request_edit"
  const handleOpenCommentsModal = () => {
    setIsCommentsModalOpen(true);
  };

  const handleCloseCommentsModal = () => {
    setIsCommentsModalOpen(false);
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [filePathList, setFilePathList] = useState([]);

  useEffect(() => {
    if (
      data?.attachments !== null &&
      data?.attachments !== "None" &&
      data?.attachments !== "[]"
    ) {
      setFilePathList(data?.attachments?.length>0 ? data?.attachments : []);
    }
  }, [data]);




  const [revData,setRevData] = useState([])

  useEffect(() => {
    if(prViewId)getRevData(prViewId)
    // getPDF(prViewId)
  },[prViewId])

  function getRevData(id){
    axiosInstance.get(`/PR/products-lists/${id}`).then(res=>{
      console.log("ree",res?.data?.data);
      if(res?.data?.data)setRevData(res?.data?.data)
      
    }).catch(err=>{
      console.log("err", err);
    })
  }

 
  const id = prViewId; 
  const getUrl = `/PR/get-status`;
  const putUrl = `/PR/edit-status`; 
  const statusUrl = '/PR/all-status';


  const downloadPDF = async () => {
    try {
        const response = await axiosInstance.get(`/PR/download-pdf/${prViewId}`, {
            responseType: 'blob' // Important to specify the response type as blob
        });

        // Create a Blob from the response
        const pdfBlob = new Blob([response.data], { type: 'application/pdf' });

        // Create a link element
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(pdfBlob);
        link.download = `${data.ref}.pdf`; // The filename you want for the downloaded file

        // Append the link to the body (required for Firefox)
        document.body.appendChild(link);

        // Programmatically click the link to trigger the download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);

    } catch (error) {
        console.error('Error downloading the PDF:', error);
    }
};

const [relatedDoc,setRelatedDoc] =useState([])

async function getRelatedDoc(){
  await axiosInstance.get(`/PR/related-docs/${prViewId}`).then(res=>{
    setRelatedDoc(res.data?.data);
  })
}


useEffect(()=>{
  getRelatedDoc()
},[prViewId,data])




  return (
    <div className="w-full h-full py-2 px-4 mb-3 relative">
      <div className="flex justify-between ">
        <Breadcrumb
          items={[
            // { name: "Procurement" },
            {
              name: "YPR",
              link: "/procurement/purchase-requests/",
            },

            { name: "View" },
          ]}
        />
        <div className=" flex gap-2">
          <BackButton />
          {(data?.status === "verified" || data?.status === "Verified") && (
            <Link
              to={{
                pathname: "/procurement/rfq/create",
              }}
              state={{ prId: prData?.prId, ref: prData?.ref }}
            >
              <CreateButton label="RFQ" />
            </Link>
          )}
          <CreateButton
            svg={false}
            handleClick={downloadPDF}
            label="Download as PDF"
          />
          {data?.editable === false ? (
            <>
              {GetRole() && (
                <Link
                  to={`/procurement/purchase-requests/edit/${prViewId}`}
                  state={{
                    data: data,
                    from: `/procurement/purchase-requests/view/${prViewId}`,
                  }}
                >
                  <EditButton height="8" />
                </Link>
              )}
            </>
          ) : (
            <>
              {GetAccess(EditAccess) && (
                <Link
                  to={`/procurement/purchase-requests/edit/${prViewId}`}
                  state={{
                    data: data,
                    from: `/procurement/purchase-requests/view/${prViewId}`,
                  }}
                >
                  <EditButton height="8" />
                </Link>
              )}
            </>
          )}
        </div>
      </div>
      <div className="w-full mx-auto h-[calc(100vh-125px)]  rounded-md overflow-hidden p-2   flex gap-2 text-[#111537]">
        <div className="w-[70%]  bg-white rounded-lg drop-shadow-lg py-1 overflow-hidden ">
          <div className="w-[98%]  bg-white px-1 mx-2 border border-gray py-2 rounded-lg flex justify-between items-center  z-10">
            <div className="grid  items-center px-1 w-[70%]">
              <div className="flex text-[14px] mb-2 text-[#111537] font-semibold ">
                <p className="w-[26%] 2xl:w-[20%] pr-[2px] ">{data?.ref} </p>
                <p> : </p>
                <p className="w-[75%] pl-[2px] text-sm font-normal text-[#606279]">
                  {data?.description || "None"}
                </p>
              </div>
              <div className="flex gap-4">
                <p className="text-xs text-[#111537]">
                  Date :{" "}
                  {data?.date ? (
                    <NuDate value={data?.date} format="DD-MMM-YYYY, hh:mm A" />
                  ) : (
                    "N/A"
                  )}
                </p>

                <p className="text-xs text-[#111537]">
                  Created By:{" "}
                  {data?.preparedByName
                    ? capitalizeFirstLetter(data?.preparedByName)
                    : "None"}
                </p>
                {/* <p className="bg-[#579BB1] text-white items-center rounded-md text-xs p-[2px]"> Status : {data.status || 'None'}</p> */}
              </div>
            </div>
            <div className="grid  w-[30%]  justify-end ">
              <div className="border rounded-md mr-1 border-[#579BB1] text-xs inline-flex w-auto  flex-shrink-0  justify-center">
                <p className="  bg-white  text-black  p-1 rounded-l-md  ">
                  Status{" "}
                </p>
                <p className="bg-[#579BB1] text-white p-1 items-center rounded-r-md">
                  {" "}
                  {data?.status || "None"}
                </p>
              </div>
            </div>
          </div>
          <div className="h-[calc(100vh-200px)] overflow-y-auto p-2 pb-8 ">
            <div className=" w-full flex flex-wrap  px-2 border border-gary mt-2  rounded-lg ">
              <div className=" w-full grid grid-cols-2 gap-x-8 ">
                <div className="w-full flex px-2">
                  <p className="w-1/3 py-2 text-xs font-medium text-[#111537]">
                    RFQ Assign.
                  </p>
                  <p className="w-[65%] py-2 text-xs pl-5 text-[#606279]">
                    :{" "}
                    {(data?.rfqAssigneeNames &&
                      data?.rfqAssigneeNames.map((item, index) => {
                        return (
                          <span key={index}>
                            {item}
                            {index !== data?.rfqAssigneeNames.length - 1
                              ? ","
                              : ""}
                          </span>
                        );
                      })) ||
                      "Not Assigned"}
                  </p>
                </div>
                <div className="w-full flex px-2">
                  <p className="w-1/3 py-2 text-xs font-medium text-[#111537]">
                    Assign. Date
                  </p>
                  <p className="w-[65%] py-2 text-xs pl-5 text-[#606279]">
                    :{" "}
                    {data?.date ? (
                      <NuDate value={data?.date} format="DD-MMM-YYYY" />
                    ) : (
                      "N/A"
                    )}
                  </p>
                </div>

                <div className="w-full flex px-2">
                  <p className="w-1/3 py-2 text-xs font-medium text-[#111537]">
                    Dead Line
                  </p>
                  <p className="w-[65%] py-2 text-xs pl-5 text-[#606279]">
                    :{" "}
                    {data?.deadLine ? (
                      <NuDate value={data?.deadLine} format="DD-MMM-YYYY" />
                    ) : (
                      "N/A"
                    )}
                  </p>
                </div>
                <div className="w-full flex px-2">
                  <p className="w-1/3 py-2 text-xs font-medium text-[#111537]">
                    Sales Person
                  </p>
                  <p className="w-[65%] py-2 text-xs pl-5 text-[#606279]">
                    : {data?.salesPersonName}
                  </p>
                </div>

                <div className="w-full flex px-2">
                  <p className="w-1/3 py-2 text-xs font-medium text-[#111537]">
                    {" "}
                    Day Elapsed{" "}
                  </p>
                  <p className="w-[65%] py-2 text-xs pl-5 text-[#606279]">
                    : {data?.daysElapsed}
                  </p>
                </div>
                <div className="w-full flex px-2">
                  <p className="w-1/3 py-2 text-xs font-medium text-[#111537]">
                    {" "}
                    Attn.{" "}
                  </p>
                  <p className="w-[65%] py-2 text-xs pl-5 text-[#606279]">
                    : {data?.attn}
                  </p>
                </div>
                {data && (
                  <>

                    {data.reviewedByName && (
                      <div className="w-full flex px-2">
                        <p className="w-1/3 py-2 text-xs font-medium text-[#111537]">
                          Reviewed By
                        </p>
                        <p className="w-[65%] py-2 text-xs pl-5 text-[#606279]">
                          : {data?.reviewedByName}
                        </p>
                      </div>
                    )}
                    {data.verifiedByName && (
                      <div className="w-full flex px-2">
                        <p className="w-1/3 py-2 text-xs font-medium text-[#111537]">
                          Verified By
                        </p>
                        <p className="w-[65%] py-2 text-xs pl-5 text-[#606279]">
                          : {data?.verifiedByName}
                        </p>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
            <div className="flex gap-4 mt-4">
              <div className="w-[50%] ">
                <p className="  text-xs font-medium text-[#111537] p-1">
                  Notes from Customer
                </p>
                <div className="  border border-gary  h-28 overflow-y-scroll rounded-lg items-center">
                  <p className="  text-xs p-2 break-words text-[#606279]">
                    {data?.notesFromCustomer || "-"}
                  </p>
                </div>
              </div>
              <div className="w-[50%] ">
                <p className=" p-1 text-xs font-medium text-[#111537]">
                  YPR Attachments
                </p>
                <div className=" border border-gary  overflow-y-scroll h-28 rounded-lg p-2">
                  {/* <SmallHeading title={"PR Attachments"} /> */}

                  <div className="w-full flex gap-2 flex-wrap ">
                    {data?.attachments &&
                      filePathList.map((imgs, idx) => {
                        console.log("img", imgs);
                        return (
                          <FilesView fileView={imgs} key={idx} />
                        );
                      })}
                  </div>
                </div>
              </div>
              
            </div>
            {/* <div className="w-full h-0.5 opacity-30 bg-[#C4C2C2] my-6 mx-auto"></div> */}

            {/* <Tabs>
{
  revData && revData.map((item,index)=>(
<Tab component={<> */}

            <div className="w-full bg-white  border border-gary  mt-2 rounded-lg ">
              <div className="w-full py-[1%] bg-baseColor-primary flex justify-between rounded-lg">
                {/* <p className=" w-[20%]  text-left text-white text-xs font-medium">
              {" "}
              Item Code
            </p> */}
                <p className="w-[5%]  text-center pl-2 text-white text-xs font-medium">
                  S.No{" "}
                </p>

                <p className=" w-[60%]   text-left text-white text-xs font-medium">
                  {" "}
                  Product{" "}
                </p>
                <p className=" w-[10%] text-center text-white text-xs font-medium">
                  {" "}
                  Unit
                </p>
                {/* <p className=" w-[20%] text-left text-white text-xs font-medium">  Category</p> */}
                <p className=" w-[15%] text-center  text-white text-xs font-medium">
                  {" "}
                  Quantity
                </p>
              </div>
              {/* {item.revProduct && item.revProduct((List[0], index) => { */}
              {revData[0]?.revProduct &&
                revData[0]?.revProduct?.map((List, index) => {
                  return (
                    <div
                      key={index}
                      className="w-full py-2 flex justify-between odd:bg-white even:bg-slate-100"
                    >
                      <p className="w-[5%]  text-center pl-2  text-xs font-medium text-[#111537] py-1">
                        {index + 1}
                      </p>
                      {/* <p className=" w-[20%] pl-1 text-left  text-xs font-medium text-[#111537] py-1">
                  {List?.item_code}
                </p> */}
                      <p className=" w-[60%]   text-left  text-xs font-medium text-[#111537] py-1">
                        {List?.product_name}
                      </p>
                      <p className=" w-[10%] text-center  text-xs font-medium text-[#111537] py-1">
                        {List?.product_unit}
                      </p>
                      {/* <p className=" w-[20%] text-left  text-xs font-medium text-[#111537] py-1">
                    {List?.product_category}
                  </p> */}

                      <p className=" w-[15%] text-center   text-xs font-medium text-[#111537] py-1">
                        {List?.quantity}
                      </p>
                    </div>
                  );
                })}
            </div>

            {/* </>} active={index==0} key={index} >
  {item?.revName}
</Tab>
  ))
}
</Tabs> */}
          </div>
        </div>
        {/* <div className="w-full h-0.5 opacity-30 bg-[#C4C2C2] my-6 mx-auto"></div> */}

        <div className="w-[30%] bg-white rounded-lg drop-shadow-lg py-1">
          <div className="h-[98%] overflow-y-scroll p-2 ">


            <ApprovalComponent
              id={id}
              getUrl={getUrl}
              putUrl={putUrl}
              statusUrl={statusUrl}
              refSetData={setData}
              data={data}
              type={"PR"}
              preUrlName={"PR"}
            />

            {data?.customerId && GetNotUseRole("procurement") && (
              <>
                <div className="w-full grid  border border-gary mt-4  rounded-lg">
                  <div className="bg-baseColor-primary  p-2 rounded-t-lg">
                    <p className="text-xs text-white font-medium">
                      Customer Detail
                    </p>
                  </div>
                  <div className=" w-full px-2">
                  <table className=" w-full border-collapse text-left">
                  {data.customerName && (
                    <TempTrCom label="Customer" value={data?.customerName||""} />                  
                  )}
                    <TempTrCom label="Reference" value={data?.customerRfqRef||""} />
                    {
                      data?.customerCat && (
                    <TempTrCom label="Category" value={data?.customerCat?.charAt(0).toUpperCase() + data?.customerCat?.slice(1)||"-"} />
                      )
                    }
                  </table>
                  </div>
                </div>

                <div className="w-full grid  border border-gary mt-4  rounded-lg ">
                  <p className="text-xs text-white font-medium bg-baseColor-primary  p-2 rounded-t-lg">
                    Documents{" "}
                  </p>
                  <div className=" w-full flex flex-wrap overflow-y-scroll h-16">
                    {relatedDoc &&
                      relatedDoc.map((doc, index) => {
                        return <PDFViewWithAPI item={doc} key={index} />;
                      })}
                  </div>
                </div>
              </>
            )}

            <div className="w-full h-auto flex  border border-gary mt-4  rounded-lg">
              <CommonComments
                visible={true}
                // closeModal={handleCloseCommentsModal}
                title="Comments"
                mainId={prViewId}
                addurl={"/pr-comments/new-comment"}
                geturl={`/pr-comments/by-PR/${prViewId}`}
              />
            </div>
          </div>
        </div>

        <Modal show={isModalOpen} onClose={() => setIsModalOpen(false)}>
          {selectedImage?.file.split(".").reverse()[0] == "pdf" ? (
            <PDFViewer pdfFile={selectedImage?.file} />
          ) : (
            <img src={selectedImage?.file} alt={selectedImage?.file} />
          )}
        </Modal>

        {showPdfComponent && (
          <div className="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-50 z-[9999] flex justify-center items-center">
            <div className="animate-scale-in relative w-[95%] h-[96%] bg-white py-2 px-4 rounded-md shadow-lg overflow-y-scroll ">
              <button
                className="absolute top-2 right-2 text-white bg-red-600"
                onClick={() => setShowPdfComponent(false)}
              >
                <svg
                  className="w-6 h-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
              <PRPdfOther data={data} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default ViewPr;


function TempTrCom({label="",value=""}){
  return (
    <tr className=" w-full">
    <td className=" w-[70px]">
     <p className=" py-2 text-xs font-medium text-[#111537]">
      {label}
     </p>
    </td>
    <td className=" w-[10px]">:</td>
     <td className=" flex-1">
      <p className=" py-2 text-xs text-[#606279] truncate text-wrap">
       {value}
      </p>
    </td>
  </tr>
  )
}
