import React from 'react'
import { TextMediumdS, TextMediumSM, TextMediumXS } from '../../../../../Components/Text/MediumText';
import UseGetDashBoardData from '../../../../../Components/Hooks/useDashboardData';
import capitalizeFirstLetter from '../../../../../Components/Utils/Funcs';

function SuppliersWithLeastLeadTimeProDash() {
  const { data, loading } = UseGetDashBoardData('/dashboard/least-lead-by-vendor');
  const dataDummy = data?.top_orders_by_value?.map(product => ({
    name: product.name,
    count: product.count,
    difference: product.difference,

  
  })) || [];
 
  return (
    <div className="col-span-2 bg-white h-[40vh] w-full p-4  rounded-lg drop-shadow-lg mb-4">
       <TextMediumSM content={"Suppliers with Least Lead Time"} color='text-[#4F4768]' />
      <div className="border-t border-[1px] border-slate-200/60 border-solid w-full mt-2"></div>
        
         {loading ? (
        <div className="flex justify-center items-center h-full">
          <div className="animate-pulse space-y-4 w-full">
            <div className="h-4 bg-slate-200 rounded"></div>
            <div className="h-4 bg-slate-200 rounded"></div>
            <div className="h-4 bg-slate-200 rounded"></div>
            <div className="h-4 bg-slate-200 rounded"></div>
          </div>
        </div>
      ) : (
        <div className="w-full h-[89%] overflow-y-scroll  pb-4">
        <table className="w-full">
          <thead className="sticky top-0 bg-white shadow-sm">
            <tr>
              <th className="text-left px-2 py-1"><TextMediumSM content="Supplier" /></th>
              <th className="text-center px-2 py-1"><TextMediumSM content="Count" /></th>
              <th className="text-left px-2 py-1"><TextMediumSM content="Difference" /></th>
             
            </tr>
          </thead>
          <tbody className=''>
            {dataDummy.map((value, index) => (
              <tr className="odd:bg-white even:bg-slate-50  " key={index}>
                <td className="px-2 py-2"><TextMediumdS content={capitalizeFirstLetter(value?.name || "N/A")} /></td>
                <td className="px-2 py-2 text-center"><TextMediumdS content={value?.count || "N/A"} /></td>
                <td className="px-2 py-2 text-left"><TextMediumdS content={value?.difference || "N/A"} /></td>
               
              </tr>
            ))}
          </tbody>
        </table>
      </div>
        
      )}
    </div>
  )
}

export default SuppliersWithLeastLeadTimeProDash;