import React, { useEffect, useMemo, useState } from "react";
import {
  BackButton,
  CancelButton,
  CreateButton,
  SubmitButton,
} from "../../../../Components/Utils/Buttons";
import {
  NuDate,
  NuInput,
  NuInputWithError,
  NuMobileNumber,
  NuTextArea,
  NuUpload,
} from "../../../../Components/Utils/Input";
import FormikSelectTwo, {
  FormikSelectWithAdd,
  SelectProductWithError,
  SelectWithError,
} from "../../../../Components/Utils/Select";
import Dashboard from "../../../../Components/Icons/Dasboard";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import UseAddData from "../../../../Components/Hooks/useAddData";
import * as yup from "yup";
import useAddFormData from "../../../../Components/Hooks/useAddFormData";
import Breadcrumb from "../../../../Components/Utils/Breadcrumb";
import Delete from "../../../../Components/Icons/Delete";
import UseDropDown from "../../../../Components/Hooks/useDropDown";
import useFetchMataData from "../../../../Components/Hooks/useFetchMataData";
import useFetchById from "../../../../Components/Hooks/useFetchById";
import UseDropDownNew from "../../../../Components/Hooks/useDDNew";
import Attachement from "../../../../Components/Utils/CustomAttrachmentupload";
import { useToast } from "../../../../Components/Others/ToastServices";
import DropDownAddMetaData, {
  DropDownAddUnitWithError,
} from "../../../../Components/Utils/selectAddMetaData";
import MultiSelect from "../../../../Components/Utils/MultiSelect";
import axiosInstance from "../../../../Services/axiosCommon";
import useFetchMetaStar from "../../../../Components/Hooks/useFetchMetaStar";
import DropDownAddSubCategory from "../../../../Components/Utils/selectAddSubCategory";
import DropDownAdd from "../../../../Components/Utils/selectAdd";
import UseCatSubCatDropDown from "../../../../Components/Hooks/useCategoryDropDown";
import EditIcon from "../../../../Components/Icons/Edit";
import UseProductDropDownNew from "../../../../Components/Hooks/useProductSearch";
import { use } from "i18next";
import UseCustomerDropDownNew from "../../../../Components/Hooks/useCustomerSearch";
import Select from "react-select";
const filetype = [
  "docx",
  "doc",
  "xlsx",
  "xls",
  "pdf",
  "txt",
  "pptx",
  "mp4",
  "mov",
  "wmv",
  "avi",
  "flv",
  "mkv",
];

function CreatePr() {
  const toast = useToast();
  const currentDate = new Date().toISOString().split("T")[0];
  const [search, setSearch] = useState("");
  const [sortedProList,setSortedProList] =  useState([]);
  const [oldData, setOldData] = useState([])
  const { data: Item, setData } = UseProductDropDownNew(
    "/items/new-drop-down",
    search
  );
  const [searchCustomer, setSearchCustomer] = useState("");
  const { data: RFQAssignee } = UseDropDown("/user/procurement/drop-down");
  const { data: Salesman } = UseDropDown("/user/salesman/drop-down");
  const { data: customers, setDataCustomer } = UseCustomerDropDownNew(
    "/customers/drop-down",
    searchCustomer
  );
  // const {value:Costcode ,getData:refCostcode} = useFetchMataData(`/dropdown/dropdown-details/`,'costCode_pr')
  // const {value:Industry ,getData:refIndustry} = useFetchMataData(`/dropdown/dropdown-details/`,'Industry_Customer')
  // const {value:Desigination,getData:refDesigination} = useFetchMataData(`/dropdown/dropdown-details/`,'Desigination_Customer')
  // // const { value:customerRating } = useFetchMetaStar(`/dropdown/dropdown-details/`,'customerRating_Customer')
  const { value: customerType, getData: refcustomerType } = useFetchMataData(
    `/dropdown/dropdown-details/`,
    "customerType_Customer"
  );
  // const {value:Category ,getData:refCategory} = useFetchMataData(`/dropdown/dropdown-details/`,'category_Customer')
  const { value: Unit, getData: refUnit } = useFetchMataData(
    `/dropdown/dropdown-details/`,
    "Unit_item"
  );
  // const [loading, setLoading] = useState(false);

  // const { value: brand } = useFetchMataData(
  //   `/dropdown/dropdown-details/`,
  //   "brand_item"
  // );
  // const { value: Manufacture } = useFetchMataData(
  //   `/dropdown/dropdown-details/`,
  //   "Manufacture_item"
  // );

  const [image, setImage] = useState([]);
  // const [division, setDivision] = useState([]);
  const { Oncancel, addFormData, contextHolder, isLoading } = useAddFormData();
  const [items, setItems] = useState([]);
  console.log(items, "ii");
  const [newItemData, setNewItemData] = useState({
    productId: "",
    quantity: "",
    product_unit: "",
  });

  const [isEditQua, setIsEditQua] = useState(false);
  const [isEditUnit, setIsEditUnit] = useState(false);

  const [createNew, setCreateNew] = useState({
    customerId: "",
    customerRfqRef: "",
    deadLine: currentDate || "",
    notesFromCustomer: "",
    costCode: "",
    rfqAssignee: [],
    salesPerson: "",
    // reasonForPurchase: "",
    comments: "",
    productList: "",
    description: "",
    // division: "",
    attn:""
  });

  const [error, setError] = useState("");
  const [productError, setProductError] = useState("");
  const [unitError, setUnitError] = useState("");
  const [quantityError, setQuantityError] = useState("");
  const handleAdd = () => {
    let hasError = false;

    if (!formik.values.productId) {
      setProductError("Product is required");
      hasError = true;
    } else {
      setProductError("");
    }

    if (!formik.values.product_unit) {
      setUnitError("Unit is required");
      hasError = true;
    } else {
      setUnitError("");
    }

    if (!formik.values.quantity) {
      setQuantityError("Quantity is required");
      hasError = true;
    } else {
      setQuantityError("");
    }

    if (hasError) return;

    if (formik.values.productId && formik.values.quantity) {
      const selectedProduct = Item.find(
        (i) => i.value === formik.values.productId
      );
      const newItem = {
        productId: formik.values.productId,
        productName: sortedProList.find((i) => i.value === formik.values.productId),
        quantity: formik.values.quantity,
        // unit:selectedProduct.unit
        product_unit: formik.values.product_unit,
      };
      setItems([...items, newItem]);

      setNewItemData({
        productId: "",
        quantity: "",
        product_unit: "",
        // unit:""
      });
      let updatedItems = Item.filter(
        (it) => it.value !== formik.values.productId
      );
      setData(updatedItems);
      formik.values.productId = "";
      formik.values.quantity = "";
      formik.values.product_unit = "";
      setError("");
    }
  };

  const handleQuantityChange = (e, index) => {
    const updatedQuantity = e.target.value;

    // Update the specific item's quantity
    const updatedItems = items.map((item, idx) => {
      if (idx === index) {
        return { ...item, quantity: updatedQuantity };
      }
      return item;
    });

    setItems(updatedItems); // Update the state with new quantity
  };

  const handleProductUnitChange = (value, index) => {
    const updatedItems = items.map((item, idx) => {
      if (idx === index) {
        return { ...item, product_unit: value?.value };
      }
      return item;
    });
    setItems(updatedItems);
  };

  const handleDeleteItem = (indexToDelete) => {
    const updatedItems = items.filter((_, index) => index !== indexToDelete);
    let ddUpdatedItem = items.find((_, i) => i === indexToDelete);
    setItems(updatedItems);
    let tempDd = ddUpdatedItem.productName;
    setData([...Item, tempDd]);
    // localStorage.setItem("items", JSON.stringify(updatedItems));
  };
  const [selectedFile, setSelectedFile] = useState(null);

  function handleImageChange(e) {
    const maxFileSize = 200000000; // 200 MB
    const files = Array.from(e.target.files);
    let fileArr = [];
    files.map((item) => {
      if (item.size < maxFileSize) {
        fileArr.push(item);
      } else {
        toast.open({
          type: "warning",
          message: `File Size to big`,
          description: `${item.name} is Removed and not Uploaded`,
          // placement: "bottomRight",
        });
      }
    });
    let tempArr = [];
    setSelectedFile(fileArr);
    files.map((item) => {
      if (item.size < maxFileSize) {
        let fileName = item?.name;
        console.log("fname", fileName);
        let fileExtention = fileName.split(".").reverse()[0];
        if (filetype?.includes(fileExtention)) {
          tempArr.push(fileName);
        } else {
          let x = URL.createObjectURL(item);
          tempArr.push(x);
        }
      }
    });
    console.log(tempArr, "temp");
    setImage(tempArr);
  }
  const handleChangeImg = (e) => {
    if (e.target.files.length) {
      setImage({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0],
      });
    }
  };
  const handleImageRemove = () => {
    setImage({
      preview: "",
      raw: "",
    });
  };
  function handleImageChange(e) {
    const maxFileSize = 200000000; // 200 MB
    const files = Array.from(e.target.files);
    let fileArr = [];
    files.map((item) => {
      if (item.size < maxFileSize) {
        fileArr.push(item);
      } else {
        toast.open({
          type: "warning",
          message: `File Size to big`,
          description: `${item.name} is Removed and not Uploaded`,
          // placement: "bottomRight",
        });
      }
    });
    let tempArr = [];
    setSelectedFile(fileArr);
    files.map((item) => {
      if (item.size < maxFileSize) {
        let fileName = item?.name;
        console.log("fname", fileName);
        // let fileExtention = fileName.split(".").reverse()[0];
        // if (filetype?.includes(fileExtention)) {
        tempArr.push(fileName);
        // } else {
        //   let x = URL.createObjectURL(item);
        //   tempArr.push(x);
        // }
      }
    });
    console.log(tempArr, "temp");
    setImage(tempArr);
  }

  const removeImages = (value) => {
    console.log("value", value);
    let List = selectedFile.filter((item) => item?.name !== value);
    console.log("LIst", List);
    setSelectedFile([...List]);
    let tempArr = [];
    List.map((item) => {
      let fileName = item?.name;
      // console.log('fname',fileName);
      // let fileExtention =  fileName.split(".").reverse()[0]
      // if(filetype?.includes(fileExtention)){
      tempArr.push(fileName);
      // }else{
      //   let x = URL.createObjectURL(item)
      //   tempArr.push(x)
      // }
    });
    setImage(tempArr);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: createNew,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object({
      customerId: yup.string().required("Customer is required!"),
      salesPerson: yup.string().required("Sales person is required!"),
      customerRfqRef: yup
        .string()
        .required("Customer RFQ Reference person is required!"),
      // division: yup.string().required("Division is required!"),
    }),
    onSubmit: async (value, { resetForm }) => {
      // setLoading(true);
      if (items.length === 0) {
        setError("Product table is empty");
        // setLoading(false)

        return;
      }
      let formData = new FormData();
      const valueWithItems = {
        ...value,
        productList: items,
      };
      formData.append("prDetails", JSON.stringify(valueWithItems));
      // formData.append('attachments', image.raw);
      if (selectedFile !== null) {
        selectedFile.forEach((file) => {
          formData.append("attachments", file);
        });
      } else {
        formData.append("attachments", []);
      }
      addFormData("/PR/new-PR", formData, resetForm);
      // setLoading(false)
    },
  });

  const [showPopup, setShowPopup] = useState(false);

  const [newProduct, setNewProduct] = useState({
    customerType: "",
    companyName: "",
    email: "",
    cpPhone: "",
    cpName: "",
    phone: "",
    countryCode: "",
    website: "",
    industry: "",
    cpCountryCode: "",
  });

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  const resetFormCustomer = () => {
    formik.values.customerType = "";
    formik.values.companyName = "";
    formik.values.email = "";
    formik.values.cpCountryCode = "";
    formik.values.cpPhone = "";
    formik.values.cpName = "";
    formik.values.phone = "";
    formik.values.countryCode = "";
    formik.values.website = "";
    formik.values.industry = "";
    setShowPopup(false);
  };

  const handlePopupSubmit = async (e) => {
    e.preventDefault();
    let CompanyName = formik.values.companyName 
    const formData = new FormData();
    // formData.append('customerImage', JSON.stringify(newProduct));
    formData.append(
      "json_data",
      JSON.stringify({
        customerType: formik.values.customerType || "",
        companyName: formik.values.companyName || "",
        email: formik.values.email || "",
        cpCountryCode: formik.values.cpCountryCode || "",
        cpPhone: formik.values.cpPhone || "",
        cpName: formik.values.cpName || "",
        phone: formik.values.phone || "",
        countryCode: formik.values.countryCode || "",
        website: formik.values.website || "",
        industry: formik.values.industry || "",
      })
    );
    try {
      const response = await axiosInstance.post(
        "/customers/new-customer",
        formData
      );
      const createdCustomer = response.data;
      if(createdCustomer?.status == true) {
        setDataCustomer((prevCustomers) => [
          ...prevCustomers,
          {
            label: CompanyName||"-",
            value: createdCustomer?.CI,
          },
        ]);
        resetFormCustomer();
      }else{
        toast.open({
          type:"warning",
          message:"Warning",
          description: createdCustomer.msg || "Warning."
        })
      }
      // if(response.data?.status){
      //   formik.resetForm();
      //   getCustomer()
      //   setShowPopup(false);
      // }
    } catch (error) {
      console.error("Error creating new customer:", error);
    }
  };
  const [showPop, setShowPop] = useState(false);

  const togglePop = () => {
    setShowPop(!showPop);
  };

  const resetForm = () => {
    formik.values.productName = "";
    formik.values.unit = "";
    formik.values.category = "";
    formik.values.subCategory = "";
    setShowPop(false);
  };

  const handlePopSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append(
      "itemDetails",
      JSON.stringify({
        productName: formik.values.productName,
        unit: formik.values.unit,
        category: formik.values.category,
        subCategory: formik.values.subCategory,
      })
    );

    try {
      const response = await axiosInstance.post("/items/new-item", formData);
      const createdProduct = response.data?.data;

      setData((prevProducts) => [
        {
          label: formik.values.productName,
          value: createdProduct.id,
          unit: createdProduct.unit,
        }, // Add the new product at the start
        ...prevProducts,
      ]);

      resetForm();
    } catch (error) {
      console.error("Error creating new product:", error);
    }
  };
  const {
    category: itemsCategory,
    subCategory: itemsSubCategory,
    getCategory,
    fetchSubCategory,
  } = UseCatSubCatDropDown(
    "/category/ItemCategory",
    "ItemCategory",
    formik?.values?.category
  );



  useEffect(()=>{
    if(Item){
      const union = Array.from(
        new Map([...Item, ...oldData].map((item) => [item.value, item])).values()
      );
      let data = union.sort((a, b) => a.label.localeCompare(b.label))
      setSortedProList(data)
    }    
  },[Item])

  useEffect(() => {
    if (formik.values.productId) {
      const findValue = sortedProList.filter((v) => formik.values?.productId ==(v.value));
      setOldData(findValue);
    }
    console.log("Testing",formik.values.productId);
  },[formik.values.productId])

 


  return (
    <div className="w-full h-full py-2 px-4 ">
      <div className="flex justify-between mb-4">
        <div>
        <Breadcrumb
          items={[
            { name: "Procurement" },
            {
              name: "YPR",
              link: "/procurement/purchase-requests/",
            },

            { name: "Create" },
          ]}
        />
        </div>
        {/* <div className="flex gap-4"> */}
        <BackButton />
       
        {/* </div> */}
      </div>
      <div className="w-full h-[calc(100vh-140px)] overflow-y-auto pb-5">
        <form onSubmit={formik.handleSubmit}>
          <div className="w-full mx-auto pb-4 bg-white rounded-md  pt-2">
            <div className=" w-full h-full flex justify-start content-start flex-wrap   mb-4">
              {/* <div className="w-full">
  <p className='pb-2 pt-4 px-4 text-sm font-medium text-[#111537] underline decoration-[#048178] decoration-2 underline-offset-4'>
  YPR:
  </p>
</div>  */}
              <NuInput
                label="Description"
                type="text"
                formik={formik}
                placeholder="Description"
                name="description"
                width="w-full md:w-1/2 lg:w-2/3"
              />

              {/* <div className=" flex items-center w-full md:w-1/2 lg:w-1/3 ml-7"> */}
              <FormikSelectWithAdd
                type="text"
                label="Customer"
                placeholder="Customer"
                Options={customers}
                formik={formik}
                name="customerId"
                width="w-full md:w-1/2 lg:w-1/3"
                isRequired={true}
                px="0"
                handleClick={togglePopup}
                setSearch={setSearchCustomer}
                FixPosition={true}
                PortalTarget={true}
              />

              {/* <button onClick={togglePopup} type="button" className="w-[12%] p-1 text-center h-9 mt-7 cursor-pointer bg-[#048178] border border-solid border-white  text-white font-semibold rounded-lg " > + </button>
            
        </div> */}

              <NuInput
                label=" Customer RFQ Reference"
                type="text"
                formik={formik}
                placeholder="Reference"
                name="customerRfqRef"
                width="w-full md:w-1/2 lg:w-1/3"
                isRequired={true}
              />
              <MultiSelect
                // type="text"
                label="RFQ Assignee"
                placeholder="RFQ Assignee"
                Options={RFQAssignee}
                formik={formik}
                name="rfqAssignee"
                width="w-full md:w-1/2 lg:w-1/3"
              />
              {/* <FormikSelectTwo
                type="text"
                label="Division"
                placeholder="Choose Division"
                Options={[
                  {
                    value: "Management",
                    label: "Management",
                  },
                  {
                    value: "Coordination",
                    label: "Coordination",
                  },
                  {
                    value: "Procurement",
                    label: "Procurement",
                  },
                  {
                    value: "Warehouse ",
                    label: "Warehouse ",
                  },
                  {
                    value: "Customer ",
                    label: "Customer ",
                  },
                  {
                    value: "Logistics ",
                    label: "Logistics ",
                  },
                  {
                    value: "Sales ",
                    label: "Sales ",
                  },
                  {
                    value: "Finance ",
                    label: "Finance ",
                  },
                ]}
                formik={formik}
                name="division"
                width="w-full md:w-1/2 lg:w-1/3"
                isRequired={true}
                FixPosition={false}
                PortalTarget={false}
              /> */}
                            <NuInput
                label="Attn."
                type="text"
                formik={formik}
                placeholder="Attn."
                name="attn"
                width="w-full md:w-1/2 lg:w-1/3"
                isRequired={true}
              />

              <NuDate
                label="Dead Line"
                placeholder="dd/mm/yyyy"
                formik={formik}
                name="deadLine"
                width="w-full md:w-1/2 lg:w-1/3"
              />
              <FormikSelectTwo
                type="text"
                label="Sales Person"
                placeholder="Choose"
                Options={Salesman}
                formik={formik}
                name="salesPerson"
                width="w-full md:w-1/2 lg:w-1/3"
                isRequired={true}
                FixPosition={false}
                PortalTarget={false}
              />

              <NuTextArea
                label="Notes From Customer"
                type="text"
                formik={formik}
                placeholder="Notes From Customer"
                name="notesFromCustomer"
                width="w-full md:w-1/2 "
                // new_width="w-full md:w-1/2"
                new_width="w-full md:w-1/2 lg:w-2/3"

              />
              {/* <NuTextArea
                label="Comments"
                type="text"
                formik={formik}
                placeholder="Comments"
                name="comments"
                width="w-full md:w-1/2 "
              /> */}

              <div className="w-full">
                <p className="pb-2 pt-4 px-4 text-sm font-medium text-[#111537] underline decoration-[#048178] decoration-2 underline-offset-4">
                  YPR Attachement :
                </p>
              </div>
              <Attachement
                handleChange={handleImageChange}
                preview={image || ""}
                width="w-full"
                label="Attach"
                multiple={true}
                removeImages={removeImages}
              />
            </div>
          </div>

          <div className="w-full mx-auto  bg-white rounded-md pb-4 mt-4 ">
            <div className=" w-full h-full flex justify-start content-start flex-wrap  mb-4">
              <div className="w-full">
                <p className="pb-2 pt-4 px-4 text-sm font-medium text-[#111537] underline decoration-[#048178] decoration-2 underline-offset-4">
                  Requested Items :
                </p>
              </div>
              <div className="w-full px-2 m-2 flex flex-col md:flex-row gap-y-3 md:gap-y-0 justify-start gap-3 border border-solid rounded-lg">
                <SelectProductWithError
                  type="text"
                  label="Product "
                  placeholder="Product "
                  Options={sortedProList}
                  formik={formik}
                  name="productId"
                  width="w-full md:w-1/2 lg:w-[67%]"
                  ErrMessage={productError}
                  px="0"
                  handleClick={togglePop}
                  setSearch={setSearch}
                  FixPosition={false}
                  PortalTarget={false}
                />
                <DropDownAddUnitWithError
                  label="Unit"
                  placeholder="Choose"
                  Options={Unit}
                  formik={formik}
                  name="product_unit"
                  width="w-full md:w-1/2 lg:w-1/4"
                  getFunc={refUnit}
                  displayProperty={"Add Unit"}
                  propertyName={"Unit_item"}
                  ErrMessage={unitError}
                  FixPosition={false}
                  PortalTarget={false}
                />
                {/* <button
                  onClick={togglePop}
                  type="button"
                  className=" p-3 py-2 mt-9  text-center h-8 cursor-pointer bg-[#048178] border border-solid border-white  text-xs font-medium  text-white  rounded-lg "
                  >
                  
                  +
                </button> */}
                <NuInputWithError
                  label="Quantity"
                  type="number"
                  formik={formik}
                  placeholder="Quantity"
                  name="quantity"
                  width="w-full md:w-1/2 lg:w-1/4 "
                  ErrMessage={quantityError}
                />
                <button
                  onClick={handleAdd}
                  type="button"
                  className=" p-1 mt-8 m-1 text-center h-8 cursor-pointer bg-[#048178] border border-solid border-white  text-xs font-medium  text-white  rounded-lg "
                >
                  Add
                </button>
              </div>
              <div className="w-full bg-white overflow-y-scroll mt-5">
                {error && (
                  <p className="text-xs font-semibold text-red-500">{error}</p>
                )}
                <div className="w-full py-3  bg-[#048178] flex justify-between rounded-lg px-6">
                  <p className="w-[5%]  text-left  text-white text-xs font-medium">
                    S.No{" "}
                  </p>

                  <p className=" w-[55%] text-left text-white text-xs font-medium">
                    Product
                  </p>
                  <p className=" w-[15%] text-center pl-4 text-white text-xs font-medium flex justify-center">
                    Unit
                    <span
                      className="pl-1 cursor-pointer"
                      onClick={() => {
                        setIsEditUnit((prv) => !prv);
                      }}
                    >
                      {isEditUnit === true && (
                        <EditIcon width={"14"} height={"14"} color="yellow" />
                      )}
                      {isEditUnit === false && (
                        <EditIcon width={"14"} height={"14"} />
                      )}
                    </span>
                  </p>
                  <p className=" w-[20%] text-center  text-white text-xs font-medium flex justify-center">
                    Quantity
                    <span
                      className="pl-1 cursor-pointer"
                      onClick={() => {
                        setIsEditQua((prv) => !prv);
                      }}
                    >
                      {isEditQua === true && (
                        <EditIcon width={"14"} height={"14"} color="yellow" />
                      )}
                      {isEditQua === false && (
                        <EditIcon width={"14"} height={"14"} />
                      )}
                    </span>
                  </p>

                  <p className=" w-[5%] text-right text-white text-xs font-medium">
                    Action
                  </p>
                </div>
                {items?.length > 0 ? (
                  <div className="h-[calc(100vh-300px)] overflow-y-scroll">
                    {items.map((List, index) => {
                      return (
                        <div
                          key={index}
                          className="w-full py-2 flex justify-between px-6  odd:bg-white even:bg-slate-50"
                        >
                          <p className="w-[5%]  text-left   text-xs font-medium text-[#111537] py-2.5">
                            {index + 1}
                          </p>
                          <p className=" w-[55%] text-left  text-xs font-medium text-[#111537] py-2.5">
                            {List?.productName?.label||""}
                          </p>
                          <p className=" w-[15%] text-center pl-4  text-xs font-medium text-[#111537]">
                            {/* {List?.product_unit} */}
                            <Select
                              value={
                                Unit.find(
                                  (item) => item?.value == List.product_unit
                                ) || List.product_unit
                              }
                              options={isEditUnit ? Unit : []}
                              className="w-full"
                              onChange={(value) =>
                                handleProductUnitChange(value, index)
                              }
                              isDisabled={!isEditUnit}
                            />
                          </p>

                          <p className=" w-[20%] text-center   text-xs font-medium text-[#111537] py-2.5">
                            <input
                              type="number"
                              className=" w-11/12 mx-auto outline-none text-center"
                              value={List?.quantity}
                              disabled={!isEditQua}
                              onChange={(e) => handleQuantityChange(e, index)}
                            />
                          </p>

                          <div className="w-[5%] text-right py-2.5">
                            <button
                              onClick={() => handleDeleteItem(index)}
                              type="button"
                              className="text-red-500 cursor-pointer  ml-4 border-none"
                            >
                              <Delete color="red" height={4} />
                            </button>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <div className="w-full text-center text-[#93949f]">
                    No Items here
                  </div>
                )}
              </div>
              <div className="w-full hidden md:block mx-auto my-4 mt-8 pb-2">
                <div className="md:flex justify-end gap-4">
                  <CancelButton
                    handleClick={() => {
                      Oncancel(-1);
                    }}
                  />
                  <SubmitButton isLoading={isLoading} />
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      {showPopup && (
        <div className="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-50 z-[9999] flex justify-center items-center">
          <div className="animate-scale-in w-[60%] max-h-[80vh] bg-white py-2 px-4 rounded-md shadow-lg overflow-y-scroll ">
            <h2 className="text-lg font-normal text-[12px]">Add Customer</h2>
            <form onSubmit={handlePopupSubmit}>
              <div className="mb-4 flex flex-wrap">
                <DropDownAddMetaData
                  label="Customer Type"
                  placeholder="Choose"
                  Options={customerType}
                  formik={formik}
                  name="customerType"
                  width="w-full md:w-1/2 lg:w-1/2"
                  getFunc={refcustomerType}
                  displayProperty={"Customer Type"}
                  propertyName={"customerType_Customer"}
                  FixPosition={true}
                  PortalTarget={false}
                />

                <NuInput
                  type="text"
                  label="Company Name"
                  placeholder="Company Name"
                  formik={formik}
                  name="companyName"
                  isRequired={true}
                  width="w-full md:w-1/2 lg:w-1/2"
                />
                <NuMobileNumber
                  nunCode="countryCode"
                  label="Phone"
                  placeholder="Phone"
                  formik={formik}
                  isRequired={true}
                  name="phone"
                  width="w-full md:w-1/2 lg:w-1/2"
                  FixPosition={true}
                  PortalTarget={false}
                />
                <NuInput
                  type="email"
                  label="Email"
                  placeholder="Email"
                  formik={formik}
                  name="email"
                  width="w-full md:w-1/2 lg:w-1/2"
                  isRequired={true}
                />
                <NuInput
                  type="text"
                  label="Contact Person Name"
                  placeholder="Contact Person Name"
                  formik={formik}
                  isRequired={true}
                  name="cpName"
                  width="w-full md:w-1/2 lg:w-1/2"
                />
                <NuMobileNumber
                  nunCode="cpCountryCode"
                  label="Contact Person Phone"
                  placeholder="Contact Person Phone"
                  formik={formik}
                  name="cpPhone"
                  isRequired={true}
                  width="w-full md:w-1/2 lg:w-1/2"
                  FixPosition={true}
                  PortalTarget={false}
                />
              </div>
              <div className="flex justify-end">
                <button
                  type="button"
                  onClick={togglePopup}
                  className="px-5 py-[9px] cursor-pointer text-xs 2xl:text-sm bg-[#E6F2F2]  hover:bg-gray-300
                  rounded-md flex items-center mr-2 text-textColor-main"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="bg-[#048178] px-5 py-[9px] cursor-pointer outline-none border-none text-white rounded-md text-xs 2xl:text-sm flex items-center"
                >
                  Add
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
      {showPop && (
        <div className="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-50 z-[9999] flex justify-center items-center">
          <div className="animate-scale-in w-[60%] max-h-[80vh] bg-white py-2 px-4 rounded-md shadow-lg overflow-y-scroll ">
            <h2 className="text-lg font-medium text-[14px]">Add Product</h2>
            <form onSubmit={handlePopSubmit}>
              <div className="mb-4 flex flex-wrap">
                <NuInput
                  label="Product Name"
                  type="text"
                  formik={formik}
                  placeholder="Product Name"
                  name="productName"
                  width="w-full"
                  isRequired={true}
                />

                <DropDownAddMetaData
                  label="Unit"
                  placeholder="Choose"
                  Options={Unit}
                  formik={formik}
                  name="unit"
                  width="w-full md:w-1/2 lg:w-1/2"
                  getFunc={refUnit}
                  displayProperty={"Add Unit"}
                  propertyName={"Unit_item"}
                  isRequired={true}
                  FixPosition={true}
                  PortalTarget={false}
                  
                />
                <DropDownAdd
                  type="text"
                  label="Category"
                  placeholder="Category"
                  Options={itemsCategory}
                  formik={formik}
                  name="category"
                  width="w-full md:w-1/2 lg:w-1/2"
                  getFunc={getCategory}
                  categoryName={"ItemCategory"}
                  displayProperty={"Add Category"}
                  isRequired={true}
                  FixPosition={true}
                  PortalTarget={false}
                />
                <DropDownAddSubCategory
                  type="text"
                  label="Sub-Category"
                  placeholder="Sub-Category"
                  Options={itemsSubCategory}
                  formik={formik}
                  name="subCategory"
                  width="w-full md:w-1/2 lg:w-1/2"
                  getFunc={fetchSubCategory}
                  category={formik?.values?.category}
                  module={"ItemCategory"}
                  displayProperty={"Add Sub-Category"}
                  FixPosition={true}
                  PortalTarget={false}
                />

              </div>
              <div className="flex justify-end">
                <button
                  type="button"
                  onClick={togglePop}
                  className="px-5 py-[9px] cursor-pointer text-xs 2xl:text-sm bg-[#E6F2F2]  hover:bg-gray-300
                  rounded-md flex items-center mr-2 text-textColor-main"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="bg-[#048178] px-5 py-[9px] cursor-pointer outline-none border-none text-white rounded-md text-xs 2xl:text-sm flex items-center"
                >
                  Add
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}

export default CreatePr;
