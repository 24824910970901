import React, { useEffect, useState } from 'react';
// import BreadCrumb from '../../../../Components/Utils/Breadcrumbs';
import { BackButton, CreateButton, EditButton } from '../../../../Components/Utils/Buttons';
import { Link, useLocation, useParams } from 'react-router-dom';
import Dashboard from '../../../../Components/Icons/Dasboard';
import Breadcrumb from '../../../../Components/Utils/Breadcrumb';
import Edit from '../../../../Components/Icons/Edit';
import Delete from '../../../../Components/Icons/Delete';
import axiosInstance from '../../../../Services/axiosCommon';
import NuDate from '../../../../Components/Utils/NuDate';
import NuAmount from '../../../../Components/Utils/NuAmount';

const ViewReceivables = () => {
  // const { patientsViewId } = useParams();
    // const location = useLocation();
    // const data = location.state.data;
    const { receivablesViewId } = useParams();
    const handleDelete = (id) =>{
      axiosInstance.delete(`/receivable/delete-payment/${id}`)
      getReceivableView()
    }
    const [data, setData] = useState([]);
  async function getReceivableView() {
    await axiosInstance
      .get(`/receivable/${receivablesViewId}`)
      .then(async (res) => {
        console.log("find", res?.data?.data);
        (await res?.data?.data) && setData(res?.data?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  console.log('sss',data?.productList);
  useEffect(()=>{
    getReceivableView();
  },[]);

  return (
    <div className="w-full h-full py-2 px-4 ">
      <div className="flex justify-between ">
        <Breadcrumb
         items={
          [
            { name: "Payment",
            link: "/payments/receivables" },  
          {
            name: "Receivables",
            link: "/payments/receivables"
          },
          {
            name: "View Receivables"
          }]
         }
        />
        <div className=" flex gap-2">
          <Link to={'/payments/receivables'}><BackButton link={true}/></Link>
          <Link to={`/payments/receivables/edit/${data?.receivableId}`} state={{data: data, from:`/payments/receivables/view/${data?.receivableId}`}}><EditButton height='8'/></Link>
        </div>
      </div>
      <div className="w-full mx-auto h-[calc(100vh-145px)] bg-white rounded-md overflow-y-scroll mt-4">
        <div className='flex justify-between'>
              <h2 className='text-base font-normal text-[#111537] p-3'>Receivable Details</h2>
              <div className='mt-3'><Link to={`/payments/receivables/add/${data?.receivableId}`}><CreateButton label='Add Paid Amount' /></Link></div>
        </div>
              <div className=" w-full flex flex-wrap ">
                <div className=" w-full grid grid-cols-3 gap-x-8 m-4">
                <div className='w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Receivable Id</p>
                    <p className='w-1/2 py-2 text-xs  text-[#606279]'>: {data?.receivableId || '-'}</p>
                  </div>
                  <div className='w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Invoice Outgoing</p>
                    <p className='w-1/2 py-2 text-xs  text-[#606279]'>: {data?.invoiceRef || '-'}</p>
                  </div>
                 
               
                  <div className='w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Received From</p>
                    <p className='w-1/2 py-2 text-xs  text-[#606279]'>: {data?.receivedFromName || '-'}</p>
                  </div>
                  <div className='w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Account Type</p>
                    <p className='w-1/2 py-2 text-xs  text-[#606279]'>: {data?.accountType || '-'}</p>
                  </div>
                  <div className='w-full flex px-2'>
  <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Amount</p>
  <p className='w-1/2 py-2 text-xs text-[#606279]'>: <NuAmount value= {data.amount}/>
   
  </p>
</div>
<div className='w-full flex px-2'>
  <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Received Amount</p>
  <p className='w-1/2 py-2 text-xs text-[#606279]'>: <NuAmount value= {data.receivedAmount}/>
  
  </p>
</div>
<div className='w-full flex px-2'>
  <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Balance Amount</p>
  <p className='w-1/2 py-2 text-xs text-[#606279]'>: <NuAmount value= {data.balanceAmount}/>
  
  </p>
</div>

                  <div className='w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Category</p>
                    <p className='w-1/2 py-2 text-xs  text-[#606279]'>: {data?.category || '-'}</p>
                  </div>
                  <div className='w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Due Date</p>
                    <p className='w-1/2 py-2 text-xs  text-[#606279]'>: {data?.dueDate ? (
                  <NuDate value={data?.dueDate} format="DD-MMM-YYYY" />
                ) : (
                  "N/A"
                )}</p>
                  </div>
                  <div className='w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Days Elapsed</p>
                    <p className='w-1/2 py-2 text-xs  text-[#606279]'>: {data?.daysElapsed || '0'}</p>
                  </div>

                  <div className='w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Description</p>
                    <p className='w-1/2 py-2 text-xs  text-[#606279]'>: {data?.description || '-'}</p>
                  </div>
                  <div className='w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Status</p>
                    <p className='w-1/2 py-2 text-xs  text-[#606279]'>: {data?.status || '-'}</p>
                  </div>
                 
                 
                 
                  </div>
                  <div className="w-full h-48 bg-white overflow-y-scroll mt-1">
            <div className="w-full py-[1%] bg-baseColor-primary flex justify-between px-3 rounded-md">
              <p className=" w-[20%] text-left text-white text-xs font-medium">
                Paid Amount
              </p>
              <p className=" w-[20%] text-left text-white text-xs font-medium">
                Bal. Amount
              </p>
              <p className=" w-[20%] text-left text-white text-xs font-medium">
                Paid On
              </p>
              <p className=" w-[20%] text-left text-white text-xs font-medium">
                Paymt Method
              </p>
              <p className=" w-[20%] text-left text-white text-xs font-medium">
                Comments
              </p>
              {/* <p className=" w-[10%] text-left text-white text-xs font-medium">
                Edit
              </p> */}
              <p className=" w-[5%] text-left text-white text-xs font-medium">
                Action
              </p>
            </div>
            {data?.paymentHistory?.map((List, index) => {
              return (
                <div key={index} className="w-full py-2 flex justify-between odd:bg-white even:bg-slate-100 px-3 rounded-lg">
                 <p className="w-[20%] text-left text-black text-xs font-light py-1">
  {List?.receivedAmount ? Number(List.receivedAmount).toLocaleString(undefined, { 
      minimumFractionDigits: 2, 
      maximumFractionDigits: 2 
    }) : '-'}
</p>
<p className="w-[20%] text-left text-black text-xs font-light py-1">
  {List?.balanceAmount ? Number(List.balanceAmount).toLocaleString(undefined, { 
      minimumFractionDigits: 2, 
      maximumFractionDigits: 2 
    }) : '-'}
</p>

                  <p className=" w-[20%] text-left text-black text-xs font-light py-1">
                    {List?.receivedOn? (
                     <NuDate value={List.receivedOn} format="DD-MMM-YYYY HH:mm" />
                    ) : (
                      "N/A"
                    )}
                  </p>
                  <p className=" w-[20%] text-left text-black text-xs font-light py-1">
                    {List?.paymentMethod}
                  </p>
                  <p className=" w-[20%] text-left text-black text-xs font-light py-1">
                    {List?.comments}
                  </p>
                  <div className=' w-[5%] text-left'>
                  <button
                    // onClick={() => handleDelete(List?.productId)}
                    type="button"
                    className="text-yellow-500 cursor-pointer bg-white  border-none"
                  >
                  <Link to={`/payments/receivables/edit/amount/${List?.id}`} state={{data:List, from:`/payments/receivables/view/${data.receivableId}`}}>
                    <Edit/>
                  </Link>
                  </button>
                  
                  <button
                    onClick={() => {
                    //  handledelete(List?.id,List?.paidAmount,List?.balanceAmount,setIsModalOpen(true))
                    handleDelete(List?.id)
                  }}
                    type="button"
                    className="text-yellow-500 cursor-pointer bg-white ml-1 border-none"
                  >
                     <Delete/>
                  </button>
                  </div>
                </div>
              );
            })}
          </div>
                  </div>
          </div>
          
      </div>
  
  )
};
export default ViewReceivables;