import React, { useState, useEffect } from "react";
import {
  BackButton,
  CancelButton,
  SubmitButton,
} from "../../../../Components/Utils/Buttons";
import FormikSelectTwo from "../../../../Components/Utils/Select";
import { useLocation, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import Breadcrumb from "../../../../Components/Utils/Breadcrumb";
import UseDropDown from "../../../../Components/Hooks/useDropDown";
import axiosInstance from "../../../../Services/axiosCommon";
import DeleteIcon from "../../../../Components/Icons/Delete";
import {
  NuInput,
  NuMobileNumber,
  NuTextArea,
} from "../../../../Components/Utils/Input";
import MultiSelect, { MultiSelectTwo } from "../../../../Components/Utils/MultiSelect";
import Attachement from "../../../../Components/Utils/CustomAttrachmentupload";
import { useToast } from "../../../../Components/Others/ToastServices";
import useAddFormData from "../../../../Components/Hooks/useAddFormData";
import EditIcon from "../../../../Components/Icons/Edit";
import UseSupplierDropDownNew from "../../../../Components/Hooks/useSupplierSearch";
import DropDownAddMetaData from "../../../../Components/Utils/selectAddMetaData";
import useFetchMataData from "../../../../Components/Hooks/useFetchMataData";
const filetype = [
  "docx",
  "doc",
  "xlsx",
  "xls",
  "pdf",
  "txt",
  "pptx",
  "mp4",
  "mov",
  "wmv",
  "avi",
  "flv",
  "mkv",
];

function CreateRFQ() {
  const location = useLocation();
  const { prId, ref } = location.state || {};
  const { isLoading, Oncancel, addFormData, contextHolder } = useAddFormData();
  const [searchSupplier, setSearchSupplier] = useState("");
  const { data: vendors, setDataSupplier } = UseSupplierDropDownNew(
    "/vendors/drop-down",
    searchSupplier
  );
  const { data: RFQAssignee } = UseDropDown("/user/procurement/drop-down");

  const { data: PR } = UseDropDown("/PR/drop-down");
  const { data: Purchaser, getData: getPurchaser } = UseDropDown(
    "/user/purchaser/drop-down"
  );
  const { value: priorityRFQ, getData: refpriorityRFQ } = useFetchMataData(
    `/dropdown/dropdown-details/`,
    "priority_RFQ"
  );
  const toast = useToast();

  const [createNew, setCreateNew] = useState({
    prId: prId || "",
    ref: ref || "",
    // vendor: "",
    vendors: [],
    comments: "",
    rfqType: "",
    localOption: "",
    purchaser: [],
    customerRfq: "",
    priority:"",
  });

  const [isEditQua, setIsEditQua] = useState(false);

  const [error, setError] = useState("");
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: createNew,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object({
      prId: yup.string().required("YPR is requred!"),
      rfqType: yup.string().required(" RFQ Type is requred!"),
    }),
    onSubmit: async (value, { resetForm }) => {
      if (prlist.length === 0) {
        setError("Product table is empty");
        return;
      }
      let formData = new FormData();

      const valueWithItems = {
        ...value,
        productList: prlist,
      };
      formData.append("json_data", JSON.stringify(valueWithItems));
      if (selectedFile !== null) {
        selectedFile.forEach((file) => {
          formData.append("attachments", file);
        });
      } else {
        formData.append("attachments", []);
      }
      addFormData("/rfq/new-rfq", formData, resetForm);
    },
  });

  const [prlist, setPrlist] = useState([]);

  const getPr = (prId) => {
    if (prId !== "") {
      axiosInstance
        .get(`/PR/pr-products/${prId}`)
        .then((res) => {
          let data = res.data.data;
          setPrlist(data);
        })
        .catch((err) => {
          console.log(err, "err");
        });
    }
  };

  const getCustomerRef = (prId) => {
    if (prId !== "") {
      axiosInstance
        .get(`/rfq/customer-ref/${prId}`)
        .then((res) => {
          let data = res.data.data;
          formik?.setFieldValue("customerRfq", data);
        })
        .catch((err) => {
          console.log(err, "err");
        });
    }
  };

  useEffect(() => {
    if (prlist.length > 0) {
      setError("");
    }
  }, [prlist]);

  useEffect(() => {
    if (formik.values.prId) {
      getPr(formik.values.prId);
    }
    if (formik.values.prId) {
      getCustomerRef(formik.values.prId);
    }
  }, [formik.values.prId]);

  const [deletedItem, setDeletedItem] = useState([]);
  const [deletedItemDropDown, setDeletedItemDropDown] = useState([]);

  const handleDeleteItem = (indexToDelete) => {
    const updatedItems = prlist.filter((_, index) => index !== indexToDelete);
    setPrlist(updatedItems);
    const deletedItems = prlist.filter((_, index) => index == indexToDelete);
    setDeletedItem((prevDeletedItems) => [
      ...prevDeletedItems,
      ...deletedItems,
    ]);
  };
  useEffect(() => {
    let tempData = [];
    deletedItem.forEach((item) => {
      let tempObject = { value: item.productId, label: item.product_name };
      tempData.push(tempObject);
    });
    setDeletedItemDropDown(tempData);
  }, [deletedItem]);

  const handleAdd = () => {
    const selectedProductId = formik.values.productId;

    const selectedProduct = deletedItem.find(
      (item) => item.productId === selectedProductId
    );

    if (selectedProduct) {
      setPrlist((prevPrList) => [...prevPrList, selectedProduct]);

      setDeletedItem((prevDeletedItems) =>
        prevDeletedItems.filter((item) => item.productId !== selectedProductId)
      );
    }
  };

  const [showPopup, setShowPopup] = useState(false);
  const [showPurchaserPopup, setShowPurchaserPopup] = useState(false);

  const [newProduct, setNewProduct] = useState({
    // customerType: "",
    companyName: "",
    email: "",
    phone: "",
    countryCode: "",
    website: "",
    industry: "",
    contactPersonMobile: "",
    contactPerson: "",
    cpCountryCode: "",
  });

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  const togglePurchaserPopup = () => {
    setShowPurchaserPopup(!showPurchaserPopup);
  };

  const handlePopupSubmit = async (e) => {
    e.preventDefault();
    let CompanyName = formik.values.companyName    
    const resetForm = () => {
      formik.values.customerType = "";
      formik.values.companyName = "";
      formik.values.email = "";
      formik.values.phone = "";
      formik.values.countryCode = "";
      formik.values.website = "";
      formik.values.industry = "";
      formik.values.cpCountryCode = "";
      formik.values.contactPerson = "";
      formik.values.contactPersonMobile = "";
      setShowPopup(false);
    };

    const formData = new FormData();
    formData.append(
      "vendorDetails",
      JSON.stringify({
        customerType: formik.values.customerType || "",
        companyName: formik.values.companyName || "",
        email: formik.values.email || "",
        phone: formik.values.phone || "",
        countryCode: formik.values.countryCode || "",
        website: formik.values.website || "",
        industry: formik.values.industry || "",
        cpCountryCode: formik.values.cpCountryCode || "",
        contactPerson: formik.values.contactPerson || "",
        contactPersonMobile: formik.values.contactPersonMobile || "",
      })
    );
    try {
      const response = await axiosInstance.post(
        "/vendors/new-vendor",
        formData
      );
      const createdSupplier = response.data;
      
      if(createdSupplier.status ==true){
        setDataSupplier((prevCustomers) => [
          ...prevCustomers,
          {
            label: CompanyName||"-",
            value: createdSupplier?.SI,
          },
        ]);
        resetForm();
      }else{
        toast.open({
          type:"warning",
          message:"Warning",
          description: createdSupplier.msg || "Warning."
        })
      }
      
    } catch (error) {
      console.error("Error creating new customer:", error);
    }
  };

  const handlePopupPurchaserSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append(
      "json_data",
      JSON.stringify({
        name: formik.values.name || "",
        designation: "localPurchaser",
        email: formik.values.email || "",
        phone: formik.values.phone || "",
        countryCode: formik.values.countryCode || "",
      })
    );
    try {
      const response = await axiosInstance.post("/user/create-user", formData);
      if (response.data?.status) {
        formik.resetForm();
        getPurchaser();
        formik.setValues((prevValues) => ({
          ...prevValues,
          name: "",
          email: "",
          phone: "",
          countryCode: "",
          division: "",
        }));
        setShowPurchaserPopup(false);
      }
    } catch (error) {
      console.error("Error creating new customer:", error);
    }
  };
  const [sortedVendors,setSortedVendors] =  useState([]);
  const [oldData, setOldData] = useState([])


  useEffect(()=>{
    if(vendors){
      const union = Array.from(
        new Map([...vendors, ...oldData].map((item) => [item.value, item])).values()
      );
      let data = union.sort((a, b) => a.label.localeCompare(b.label))
      setSortedVendors(data)
    }
  },[vendors])


  useEffect(() => {
    if (formik.values.vendors) {
      const findValue = sortedVendors.filter((v) =>
        formik.values?.vendors?.includes(v.value)
      );
      console.log("Testing", findValue);
      setOldData(findValue);
    }
    console.log("Testing",formik.values.vendors);

  },[formik.values.vendors])

  const [selectedFile, setSelectedFile] = useState(null);
  const [image, setImage] = useState([]);

  function handleImageChange(e) {
    const maxFileSize = 200000000; // 200 MB
    const files = Array.from(e.target.files);
    let fileArr = [];
    files.map((item) => {
      if (item.size < maxFileSize) {
        fileArr.push(item);
      } else {
        toast.open({
          type: "warning",
          message: `File Size to big`,
          description: `${item.name} is Removed and not Uploaded`,
          // placement: "bottomRight",
        });
      }
    });
    let tempArr = [];
    setSelectedFile(fileArr);
    files.map((item) => {
      if (item.size < maxFileSize) {
        let fileName = item?.name;
        console.log("fname", fileName);
        let fileExtention = fileName.split(".").reverse()[0];
        if (filetype?.includes(fileExtention)) {
          tempArr.push(fileName);
        } else {
          let x = URL.createObjectURL(item);
          tempArr.push(x);
        }
      }
    });
    console.log(tempArr, "temp");
    setImage(tempArr);
  }

  const removeImages = (value) => {
    console.log("value", value);
    let List = selectedFile.filter((item) => item?.name !== value);
    console.log("LIst", List);
    setSelectedFile([...List]);
    let tempArr = [];
    List.map((item) => {
      let fileName = item?.name;
      tempArr.push(fileName);
    });
    setImage(tempArr);
  };

  const handleQuantityChange = (e, index) => {
    const updatedItems = [...prlist];
    updatedItems[index].quantity = e.target.value;
    setPrlist(updatedItems);
  };

  return (
    <div className="w-full h-full py-2 px-4">
      <div className="flex justify-between">
        <Breadcrumb
          items={[
            { name: "RFQ", link: "/procurement/rfq/" },
            { name: "Create" },
          ]}
        />
        <BackButton />
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className="w-full mx-auto h-[calc(100vh-145px)] bg-white rounded-md overflow-hidden mt-4">
          <div className="w-full h-full py-4 px-3 flex justify-start content-start flex-wrap overflow-y-auto">
          {!location.state && (
            <FormikSelectTwo
              type="text"
              label="YPR"
              placeholder="YPR"
              Options={PR}
              formik={formik}
              name="prId"
              width="w-full md:w-1/2 lg:w-1/3"
              isRequired={true}
              FixPosition={false}
              PortalTarget= {false}
            />
          )}
            {location.state && (
 <FormikSelectTwo
              type="text"
              label="YPR"
              placeholder="YPR"
              Options={PR} 
              formik={formik}
              name="prId"
              width="w-full md:w-1/2 lg:w-1/3"
              isRequired={true}
              FixPosition={false}
              PortalTarget={false}
              value={formik.values.prId} 
              disabled={true} 
            />
          )}
            <NuInput
              label="Customer Reference"
              type="text"
              formik={formik}
              placeholder="Customer Reference"
              name="customerRfq"
              width="w-full md:w-1/2 lg:w-1/3"
              disabled={true}
            />
             {/* <MultiSelect
                // type="text"
                label="RFQ Assignee"
                placeholder="RFQ Assignee"
                Options={RFQAssignee}
                formik={formik}
                name="rfqAssignee"
                width="w-full md:w-1/2 lg:w-1/3"
              /> */}
            <DropDownAddMetaData
              label="Priority"
              placeholder="Choose"
              Options={priorityRFQ}
              formik={formik}
              name="priority"
              width="w-full md:w-1/2 lg:w-1/3"
              getFunc={refpriorityRFQ}
              displayProperty={"Priority RFQ"}
              propertyName={"priority_RFQ"}
            />
            <FormikSelectTwo
              type="text"
              label="RFQ Type"
              placeholder="RFQ Type"
              Options={[
                { value: "local", label: "Local" },
                { value: "international", label: "International" },
              ]}
              formik={formik}
              name="rfqType"
              width="w-full md:w-1/2 lg:w-1/3"
              isRequired={true}
            />
            {formik.values.rfqType === "local" && (
              <div className="w-full px-6 md:w-1/2 lg:w-1/3">
                <div className="flex items-center mt-8">
                  <input
                    type="radio"
                    name="localOption"
                    value="purchaser"
                    checked={formik.values.localOption === "purchaser"}
                    onChange={() =>
                      formik.setFieldValue("localOption", "purchaser")
                    }
                  />
                  <label className="ml-2 text-sm 2xl:text-sm">
                    Local Purchaser
                  </label>
                </div>
                <div className="flex items-center mt-1">
                  <input
                    type="radio"
                    name="localOption"
                    value="supplier"
                    checked={formik.values.localOption === "supplier"}
                    onChange={() =>
                      formik.setFieldValue("localOption", "supplier")
                    }
                  />
                  <label className="ml-2 text-sm 2xl:text-sm">Supplier</label>
                </div>
              </div>
            )}

            {formik.values.rfqType === "international" && (
              <MultiSelectTwo
                type="text"
                label="Supplier"
                placeholder="Choose"
                Options={sortedVendors}
                formik={formik}
                name="vendors"
                width="w-full md:w-1/2 lg:w-1/3"
                px="0"
                handleClick={togglePopup}
                setSearch={setSearchSupplier}
              />
            )}

            {formik.values.rfqType === "local" &&
              formik.values.localOption === "purchaser" && (
                <MultiSelect
                  type="text"
                  label="Local Purchaser"
                  placeholder="Choose"
                  Options={Purchaser}
                  formik={formik}
                  name="purchaser"
                  width="w-full md:w-1/2 lg:w-1/3"
                  px="0"
                  // handleClick={togglePurchaserPopup}
                />
              )}
            {formik.values.rfqType === "local" &&
              formik.values.localOption === "supplier" && (
                // <div className=" flex items-center w-full md:w-1/2 lg:w-1/3 ">
                <MultiSelectTwo
                  type="text"
                  label="Supplier"
                  placeholder="Choose"
                  Options={sortedVendors}
                  formik={formik}
                  name="vendors"
                  width="w-full md:w-1/2 lg:w-1/3"
                  px="0"
                  handleClick={togglePopup}
                  setSearch={setSearchSupplier}
                />

              )}
            <NuTextArea
              label="Comments"
              type="text"
              formik={formik}
              placeholder="Comments"
              name="comments"
              width="w-full md:w-1/2 lg:w-2/3"
              new_width="w-full md:w-1/2 lg:w-2/3"
            />
            <div className="w-full">
              <p className="pb-2 pt-4 px-4 text-sm font-medium text-[#111537] underline decoration-[#048178] decoration-2 underline-offset-4">
                RFQ Attachement :
              </p>
            </div>
            <Attachement
              handleChange={handleImageChange}
              preview={image || ""}
              width="w-full"
              label="Attach"
              multiple={true}
              removeImages={removeImages}
            />

            <div className="w-full h-[1px] opacity-30 bg-[#C4C2C2] my-6 mx-auto"></div>
            {deletedItemDropDown.length > 0 ? (
              <div className="w-full flex justify-start gap-2">
                <FormikSelectTwo
                  type="text"
                  label="Product"
                  placeholder="Product"
                  Options={deletedItemDropDown}
                  formik={formik}
                  name="productId"
                  width="w-full md:w-1/2 lg:w-[67%]"
                />
                <button
                  onClick={handleAdd}
                  type="button"
                  className=" p-1 mt-[44px] m-1 text-center h-8 cursor-pointer bg-[#048178] border border-solid border-white  text-xs font-medium  text-white  rounded-lg "
                >
                  Add
                </button>
              </div>
            ) : null}
            <div className="w-full bg-white overflow-y-scroll mt-5">
              {error && (
                <p className="text-xs font-semibold text-red-500">{error}</p>
              )}
              <div className="w-full py-2 bg-[#048178] flex justify-between rounded-lg px-6">
                <p className="w-[5%]  text-left  text-white text-xs font-medium">
                  S.No{" "}
                </p>
                <p className="w-[60%] text-left text-white text-xs font-medium">
                  Product
                </p>
                <p className="w-[10%] text-center text-white text-xs font-medium">
                  Unit
                </p>
                <p className="w-[20%] text-center text-white text-xs font-medium flex justify-center">
                  Quantity
                  <span
                    className="pl-1 cursor-pointer"
                    onClick={() => {
                      setIsEditQua((prv) => !prv);
                    }}
                  >
                    {isEditQua === true && (
                      <EditIcon width={"14"} height={"14"} color="yellow" />
                    )}
                    {isEditQua === false && (
                      <EditIcon width={"14"} height={"14"} />
                    )}
                  </span>
                </p>
                <p className="w-[5%] text-center text-white text-xs font-medium">
                  Action
                </p>
              </div>
              {prlist?.length > 0 ? (
                <div className="h-[calc(100vh-300px)] overflow-y-scroll">
                  {prlist.map((List, index) => (
                    <div
                      key={index}
                      className="w-full py-2 flex justify-between px-6 odd:bg-white even:bg-slate-100"
                    >
                      <p className="w-[5%]  text-left  text-xs font-medium text-[#111537] py-1">
                      {List?.sno||index + 1}
                      </p>
                      <p className="w-[60%] text-left text-xs font-medium text-[#111537]">
                        {List?.product_name}
                      </p>
                      <p className="w-[10%] text-center text-xs font-medium text-[#111537]">
                        {List?.product_unit}
                      </p>
                      <p className="w-[20%] text-center text-xs font-medium text-[#111537]">
                        <input
                          type="number"
                          className="w-full outline-none text-center"
                          name="quantity"
                          formik={formik}
                          value={List?.quantity || ""}
                          disabled={!isEditQua}
                          onChange={(e) => handleQuantityChange(e, index)}
                        />
                      </p>
                      <div className="w-[5%] text-center">
                        <button
                          onClick={() => handleDeleteItem(index)}
                          type="button"
                          className="text-red-500 cursor-pointer ml-4 border-none"
                        >
                          <DeleteIcon color="red" height={4} />
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="w-full text-center text-[#93949f]">
                  No Items here
                </div>
              )}
            </div>
            <div className="w-full hidden md:block mx-auto my-4 mt-8">
              <div className="md:flex justify-end gap-4">
                <CancelButton
                  handleClick={() => {
                    Oncancel(-1);
                  }}
                />
                <SubmitButton isLoading={isLoading} />
              </div>
            </div>
          </div>
        </div>
      </form>
      {showPopup && (
        <div className="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-50 z-[9999] flex justify-center items-center">
          <div className="animate-scale-in w-[60%] max-h-[80vh] bg-white py-2 px-4 rounded-md shadow-lg overflow-y-scroll ">
            <h2 className="text-lg font-normal text-[12px]">Add Supplier</h2>
            <form onSubmit={handlePopupSubmit}>
              <div className="mb-4 flex flex-wrap">
                <NuInput
                  type="text"
                  label="Company Name"
                  placeholder="company Name"
                  formik={formik}
                  name="companyName"
                  isRequired={true}
                  width="w-full md:w-1/2 lg:w-1/2"
                />

                <NuMobileNumber
                  nunCode="countryCode"
                  label="Phone"
                  placeholder="Phone"
                  formik={formik}
                  name="phone"
                  isRequired={true}
                  width="w-full md:w-1/2 lg:w-1/2"
                  FixPosition={true}
                  PortalTarget={false}
                />
                <NuInput
                  type="email"
                  label="Email"
                  placeholder="Email"
                  formik={formik}
                  name="email"
                  width="w-full md:w-1/2 lg:w-1/2"
                  isRequired={true}
                />
                <NuInput
                  type="text"
                  label="Website"
                  placeholder="Website"
                  formik={formik}
                  name="website"
                  // isRequired={true}
                  width="w-full md:w-1/2 lg:w-1/2"
                />
                <NuInput
                  type="text"
                  label="Contact Person"
                  placeholder="contact Person"
                  formik={formik}
                  isRequired={true}
                  name="contactPerson"
                  width="w-full md:w-1/2 lg:w-1/2"
                />
                <NuMobileNumber
                  nunCode="cpCountryCode"
                  label="Contact Person Mobile"
                  placeholder="Contact Person Mobile"
                  formik={formik}
                  isRequired={true}
                  name="contactPersonMobile"
                  width="w-full md:w-1/2 lg:w-1/2"
                  FixPosition={true}
                  PortalTarget={false}
                />
              </div>
              <div className="flex justify-end">
                <button
                  type="button"
                  onClick={togglePopup}
                  className="px-5 py-[9px] cursor-pointer text-xs 2xl:text-sm bg-[#E6F2F2]  hover:bg-gray-300
                  rounded-md flex items-center mr-2 text-textColor-main"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="bg-[#048178] px-5 py-[9px] cursor-pointer outline-none border-none text-white rounded-md text-xs 2xl:text-sm flex items-center"
                >
                  Add
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
      {/* {showPurchaserPopup && (
        <div className="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-50 z-[9999] flex justify-center items-center">
          <div className="animate-scale-in w-[60%] max-h-[80vh] bg-white py-2 px-4 rounded-md shadow-lg overflow-y-scroll ">
            <h2 className="text-lg font-normal text-[12px]">
              Add Local Purchaser
            </h2>
            <form onSubmit={handlePopupPurchaserSubmit}>
              <div className="mb-4 flex flex-wrap">
                <NuInput
                  type="text"
                  label="Name"
                  placeholder="Enter Name"
                  formik={formik}
                  name="name"
                  isRequired={true}
                  width="w-full md:w-1/3 md:-mt-2"
                />
                <NuInput
                  type="email"
                  label="Email"
                  placeholder="Email"
                  formik={formik}
                  name="email"
                  isRequired={true}
                  width="w-full md:w-1/3 md:-mt-2"
                />
                <NuMobileNumber
                  nunCode="countryCode"
                  label="Phone"
                  placeholder="Phone"
                  formik={formik}
                  name="phone"
                  width="w-full md:w-1/3 md:-mt-2"
                />
              </div>
              <div className="flex justify-end">
                <button
                  type="button"
                  onClick={togglePurchaserPopup}
                  className="px-5 py-[9px] cursor-pointer text-xs 2xl:text-sm bg-[#E6F2F2]  hover:bg-gray-300
                  rounded-md flex items-center mr-2 text-textColor-main"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="bg-[#048178] px-5 py-[9px] cursor-pointer outline-none border-none text-white rounded-md text-xs 2xl:text-sm flex items-center"
                >
                  Add
                </button>
              </div>
            </form>
          </div>
        </div>
      )} */}
    </div>
  );
}

export default CreateRFQ;
