import React, { useEffect, useState } from "react";
import MultiSelect from "../../../../Components/Utils/MultiSelect";
import { useFormik } from "formik";
import { useToast } from "../../../../Components/Others/ToastServices";
import { TextMediumSM } from "../../../../Components/Text/MediumText";
import {
  CancelButton,
  SubmitButton,
} from "../../../../Components/Utils/Buttons";
import axiosInstance from "../../../../Services/axiosCommon";
import * as yup from "yup";
import UseDropDown from "../../../../Components/Hooks/useDropDown";
import EditIcon from "../../../../Components/Icons/Edit";
import DeleteIcon from "../../../../Components/Icons/Delete";

function SupplierPermission() {
  const toast = useToast();
  const { data: user } = UseDropDown("/user/drop-down");
  const [data, setData] = useState([]);
  const [showFilterDialog, setShowFilterDialog] = useState(false);
  const [createNew, setCreateNew] = useState({
    module: "supplier",
    users: [],
  });
  const [editDataStatus, setEditDataStatus] = useState("");
  const [editApprovalType, setEditApprovalType] = useState("");
  const [lists, setLists] = useState([]);
  const [sortedUser,setSortedUser] =useState([])
  const getData = () => {
    axiosInstance
      .get("/status-permission/supplier")
      .then((res) => {
        console.log("test",res?.data?.data);
        
        setData(res?.data?.data || []);
        let tempArray = [];
        let value = res?.data?.data;
        value.map((item) => {
          let x = {
            value: item.userId,
            label: item.userName,
          }
          tempArray.push(x);
        })
        setLists(tempArray);
      })
      .catch(() => {
        toast.open({
          type: "error",
          message: "Error",
          description: "Failed to fetch data.",
        });
      });
  };

  useEffect(() => {
    getData();
  }, []);

  
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: createNew,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object({
          users: yup.array().of(yup.string()).min(1, "At least one user is required!"),
    }),
    onSubmit: async (value, { resetForm }) => {
      axiosInstance
        .post("/status-permission/supplier", value)
        .then((res) => {
          if (res.data?.status === true) {
            toast.open({
              type: "success",
              message: "Success",
              description: res.data?.msg || "Created Successfully.",
            });
            resetForm();
            getData();
          } else if (res.data?.status === false && res.data?.error) {
            toast.open({
              type: "error",
              message: "Error",
              description: res.data.error || "Network error.",
            });
          } else {
            toast.open({
              type: "warning",
              message: "Warning",
              description: res.data?.msg || "Warning.",
            });
          }
        });
    },
  });

 
  const test = () => {
    let temp = [];
    Object.keys(data).map((key) => {
      if (key === editDataStatus) {
        data[editDataStatus]?.map((item) => {
          temp.push(item?.userId);
        });
      }
    });
    return temp;
  };
  const getInitials = (name) => {
    const names = name.split(" ");
    if (names.length > 1) {
      return names[0][0] + names[1][0];
    }
    return names[0][0];
  };
  const [createNewEdit, setCreateNewEdit] = useState({
    module: "purchase_request",
    status:"verified",
    users: [],
    approvalType: "signature",
    division: ""
  });
  useEffect(() => {
    setCreateNewEdit({
      module: "purchase_request",
      status: "verified",
      users: test() || [],
      approvalType: "signature",
      division: editDataStatus || ""
    });
  }, [editDataStatus]);

  
  function handleDelete(userId) {
    axiosInstance
      .delete(`/status-permission/supplier/${userId}`)
      .then((res) => {
        if (res?.data?.status == true) {
          getData();
          toast.open({
            type: "success",
            message: "Success",
            description: res.data?.msg || "Deleted Successfully.",
          });
        } else {
          toast.open({
            type: "error",
            message: "Error",
            description: res.data.error || "Network error.",
          });
        }
      })
      .catch((err) => {
        toast.open({
          type: "error",
          message: "Error",
          description: err.response.data.error || "Delete failed.",
        });
      });
  }

  useEffect(()=>{
    if(user){
      const union = user.filter(item1 => !lists.some(item2 => item1.value === item2.value))
      let data = union.sort((a, b) => a.label.localeCompare(b.label))
      setSortedUser(data)
    }
  },[user])

  return (
    <div>
      <div className="ml-5">
        <TextMediumSM content={"Supplier Approval"} />
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className="flex justify-start">
          <MultiSelect
            label="User Name"
            placeholder="Choose"
            Options={sortedUser}
            formik={formik}
            name="users"
            isRequired={true}
            width="w-full md:w-1/2 lg:w-1/4"
            // FixPosition={true}
          />
          <div className="w-10 h-12 mt-11">
            <SubmitButton name="Add" />
          </div>
        </div>
      </form>

      <div className="mt-8 max-h-64 overflow-y-auto relative">
  <table className="w-[75%] ml-5 mt-2">
    <thead className="sticky top-0 bg-white z-1"> 
      <tr className="border-b border-black">
        <th className="w-[60%] text-left text-sm font-medium py-2 px-4">
          Users
        </th>
        <th className="w-[20%] text-left text-sm font-medium py-2">
          Action
        </th>
      </tr>
    </thead>
    <tbody>
      {data &&
        data.map((value, index) => (
          <tr className="even:bg-white odd:bg-slate-50" key={index}>
            <td className="w-[60%]">
              <div className="w-full flex gap-x-4 py-3 pl-2 text-sm text-[#606279]">
                <div
                  key={index}
                  className="circle"
                  style={{
                    width: "30px",
                    height: "30px",
                    borderRadius: "50%",
                    backgroundColor: "#048178",
                    color: "white",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontWeight: "normal",
                  }}
                >
                  {getInitials(value?.userName.charAt(0))}
                </div>
                <div className="circle-tooltip">{value?.userName}</div>
              </div>
            </td>
            <td className="w-[20%] text-center py-2">
              <div className="w-full">
                <div
                  className="flex cursor-pointer"
                  onClick={() => handleDelete(value?.id)}
                >
                  <DeleteIcon />
                </div>
              </div>
            </td>
          </tr>
        ))}
    </tbody>
  </table>
</div>

    </div>
  );
}

export default SupplierPermission;
