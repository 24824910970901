import React, { useEffect, useState } from "react";
import {TextMediumSM} from "../../../../Components/Text/MediumText";
import UseGetDashBoardData from "../../../../Components/Hooks/useDashboardData";
import {
  Cell,
  Label,
  Pie,
  PieChart,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { NODataChart } from "./PurchaseOrdersfromCustomers";
import { useNavigate } from "react-router-dom";


function PurchaseRequestDashboard() {
  const { data, loading } = UseGetDashBoardData(`/dashboard/purchaseRequest`);
  const navigate = useNavigate();

  const chartData = data?.customer_category_wise?.map(
    (item) => ({
      name: `${
        item.category
          ? `${
              item.category.charAt(0).toUpperCase() +
              item.category.slice(1).toLowerCase()
            }`
          : "Unknown"
      }`,
      value: item?.count || 0,
    })
  );

  console.log("chart", chartData);

  let COLORS = ["#FF6384", "#36A2EB", "#FFCE56", "#4BC0C0", "#9966FF"];

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      return (
        <div className=" max-w-[250px] bg-white shadow-[rgba(13,_38,_76,_0.19)_0px_9px_20px] rounded-lg p-[5px]">
          <p className=" text-xs font-medium">{`Category  ${payload[0].name} : ${payload[0].value}`}</p>
        </div>
      );
    }

    return null;
  };

  const [sumOfChartData, setSumOfChartData] = useState(0);

  useEffect(() => {
    setSumOfChartData(
      data?.customer_category_wise?.reduce(
        (acc, item) => acc + item?.count,
        0
      ) || 0
    );
  }, [data?.customer_category_wise]);

  const handleSliceClick = (entry) => {
        const path = "/procurement/purchase-requests";
    navigate(path, { state: { category: entry.name } });
  };

  return (
    <div className="h-[40vh] w-full bg-white p-2 pb-10 rounded-lg drop-shadow-lg overflow-hidden mb-4">
      <div className="w-full px-1 py-[2px] rounded-md flex justify-between items-center p-4">
        <TextMediumSM content={"Customer YPR"} color="text-[#4F4768]" />
      </div>
      <div className="border-t border-[1px] border-slate-200/60 border-solid w-full mt-2"></div>

      {loading ? (
        <LoadOverall />
      ) : (
        <div className="h-[96%] overflow-y-scroll">
          <div className="flex gap-2 w-full">
            {data?.customer_category_wise?.length > 0 ? (
              <>
                {sumOfChartData > 0 ? (
                  <div className=" w-[40%] ">
                    {/* <Doughnut data={chartData} /> */}
                    <div className="w-full flex justify-center items-center">
                      <ResponsiveContainer width="100%" height={150}>
                        <PieChart>
                          <Pie
                            data={chartData}
                            cx="50%"
                            cy="50%"
                            innerRadius={35}
                            outerRadius={60}
                            fill="#8884d8"
                            dataKey="value"
                            // paddingAngle={0}
                             style={{ cursor: 'pointer' }}
                             onClick={(entry) => handleSliceClick(entry)}
                          >
                            {chartData.map((entry, index) => (
                              <Cell
                                key={`cell-${index}`}
                                fill={COLORS[index % COLORS.length]}
                                dataKey="name"
  onFocus={(e) => e.target.blur()} 

                              />
                            ))}
                            <Label
                              value={`${data?.total_count}`}
                              position="center"
                              className="text-center"
                              style={{ fontSize: "12px", fontWeight: "bold" }}
                            />
                          </Pie>
                          <Tooltip content={<CustomTooltip />} />
                        </PieChart>
                      </ResponsiveContainer>
                    </div>
                  </div>
                ) : (
                  <NODataChart />
                )}
              </>
            ) : (
              <NODataChart />
            )}
            <div className="w-[58%]">
              <table className=" w-full">
                <tbody>
                  {data?.customer_category_wise?.map((item, index) => (
                    <tr key={index} className="">
                      <td className=" w-[10%]">
                        <div
                          style={{ background: COLORS[index % COLORS.length] }}
                          className={`w-3 h-3 rounded-full mb-3`}
                        ></div>
                      </td>
                      <td className=" w-[55%] py-1">
                        <p className="text-xs 2xl:text-sm truncate">
                          Category{" "}
                          {item.category
                            ? `${
                                item.category.charAt(0).toUpperCase() +
                                item.category.slice(1).toLowerCase()
                              }`
                            : ""}
                        </p>
                        <p className="text-[#048178] text-[10px] text-start">
  {isNaN(Number(item.difference)) ? "0.00" : Number(item.difference).toFixed(2)}
</p>


                      </td>
                      <td className=" w-[35%] py-1">
                        <p className="text-[#333333] text-base 2xl:text-[14px] font-semibold text-center">
                          {`${item.count || 0}`}
                        </p>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default PurchaseRequestDashboard;

export function LoadOverall() {
  return (
    <div className=" w-full h-full flex justify-between p-3 animate-pulse">
      <div class="flex items-center justify-center w-28 h-28 xl:w-32 xl:h-32 bg-gray-200 rounded-full ">
        <div class="flex items-center justify-center w-16 h-16 xl:w-20 xl:h-20 bg-white rounded-full "></div>
      </div>
      <div role="status" className=" w-[60%] p-2 space-y-2 ">
        <div className="flex items-center justify-between">
          <div className=" flex gap-3">
            <div className="h-4 bg-gray-300 rounded-full  w-4"></div>
            <div>
              <div className="h-2.5 bg-gray-300 rounded-full  w-12 mb-2.5"></div>
              <div className="w-24 h-2 bg-gray-200 rounded-full "></div>
            </div>
          </div>
          <div className=" flex flex-col justify-between items-center">
            <div className="h-2.5 bg-gray-300 rounded-full  w-6 mb-2.5"></div>
            <div className="w-12 h-2 bg-gray-200 rounded-full "></div>
          </div>
        </div>

        <div className="flex items-center justify-between">
          <div className=" flex gap-3">
            <div className="h-4 bg-gray-300 rounded-full  w-4"></div>
            <div>
              <div className="h-2.5 bg-gray-300 rounded-full  w-12 mb-2.5"></div>
              <div className="w-24 h-2 bg-gray-200 rounded-full "></div>
            </div>
          </div>
          <div className=" flex flex-col justify-between items-center">
            <div className="h-2.5 bg-gray-300 rounded-full  w-6 mb-2.5"></div>
            <div className="w-12 h-2 bg-gray-200 rounded-full "></div>
          </div>
        </div>

        <div className="flex items-center justify-between">
          <div className=" flex gap-3">
            <div className="h-4 bg-gray-300 rounded-full  w-4"></div>
            <div>
              <div className="h-2.5 bg-gray-300 rounded-full  w-12 mb-2.5"></div>
              <div className="w-24 h-2 bg-gray-200 rounded-full "></div>
            </div>
          </div>
          <div className=" flex flex-col justify-between items-center">
            <div className="h-2.5 bg-gray-300 rounded-full  w-6 mb-2.5"></div>
            <div className="w-12 h-2 bg-gray-200 rounded-full "></div>
          </div>
        </div>

        <span class="sr-only">Loading...</span>
      </div>
    </div>
  );
}
