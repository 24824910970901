import React, { useEffect, useState } from "react";
import Breadcrumb from "../../../Components/Utils/Breadcrumb";
import SearchBar from "../../../Components/Utils/SearchBar";
import axiosInstance from "../../../Services/axiosCommon";
import { Link, Outlet, useParams } from "react-router-dom";
import { useSocket } from "../../../Context/SocketContext";

function ChatIndex() {
  const {handleUnreadMessages} =useSocket()
  const { chatID } = useParams();
  const [searchQuery, setSearchQuery] = useState("");
  const [data, setData] = useState([]);

  function SearchData(e) {
    e.preventDefault();
    if (searchQuery != "") {
      axiosInstance
        .get(`/customer/search-customers/${searchQuery}`)
        .then((res) => {
          res.data?.data && setData(res.data?.data);
        })
        .catch((err) => console.log(err));
    } else {
      getUsers();
    }
  }

  async function getUsers() {
    await axiosInstance
      .get(`/groups/`)
      .then(async (res) => {
        console.log("find", res?.data?.data);
        (await res?.data?.data) && setData(res?.data?.data);
        // setTotalProject(res.data?.data?.totalRecords);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    if (searchQuery == "") {
      getUsers();
    }
  }, [searchQuery]);


    useEffect(() => {
      handleUnreadMessages((data)=>{
          if (chatID !== data.sender_id) {
            setData((prevData) =>
              prevData.map((user) =>
                user.id === data.sender_id
                  ? { ...user, unread: (user.unread || 0) + 1 }
                  : user
              )
            );
          }
      })
      
    },[handleUnreadMessages])

  return (
    <div className=" w-full h-[calc(100vh-90px)] px-4 py-2 overflow-y-scroll">
      <div className="md:flex justify-between items-center w-full">
        <Breadcrumb
          items={[
            {
              name: "Chat",
            },
          ]}
        />
        <div className=" flex justify-between items-center">
          <div className=" pb-2 w-full flex justify-between items-center ">
            <div className="flex justify-end items-center gap-1">
              <form className="flex items-center mx-0" onSubmit={SearchData}>
                <SearchBar
                  handleChange={(e) => setSearchQuery(e.target.value)}
                />
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full h-[calc(100vh-150px)] bg-white flex rounded-md overflow-hidden">
        <div className="w-4/12 relative h-full border-r border-slate-200 overflow-y-auto">
          <div className="w-full sticky top-0 rounded-tl-md h-14 bg-baseColor-secondary pt-2 px-2">
            <p className="py-2 px-4 text-sm 2xl:text-base font-medium  text-white">
              All Members
            </p>
          </div>
          {data &&
            data.map((item, idx) => {
              return (
                <React.Fragment key={idx}>
                  <Link
                    to={`/chat/view/chatProfile/${item?.id}`}
                    onClick={() =>
                      setData((prevData) =>
                        prevData.map((user) =>
                          user.id === item.id ? { ...user, unread: 0 } : user
                        )
                      )
                    }
                  >
                    <div
                      className={`${
                        chatID && (chatID == item?.id ? "bg-teal-100" : "")
                      } p-3`}
                    >
                      <div className={`w-full flex `}>
                        <div className="w-11/12 ">
                          <div className="w-full flex justify-start gap-2">
                            <div
                              className={` w-7 h-7 2xl:w-8 2xl:h-8 bg-baseColor-secondary rounded-full overflow-hidden mt-0.5 flex items-center justify-center `}
                            >
                              {item.image ? (
                                <img
                                  src={item.image}
                                  width="50px"
                                  alt={`${item.image}`}
                                  className="max-w-full max-h-full"
                                />
                              ) : (
                                <h1 className="font-semibold text-teal-100 text-base 2xl:text-lg">
                                  {item?.name?.[0]}
                                </h1>
                              )}
                            </div>
                            <div className="flex mt-2 flex-col truncate">
                              <p className=" text-xs 2xl:text-sm font-medium text-[#4F4768]">
                                {item?.name}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="w-1/12 flex justify-end gap-x-2 ">
                          <div className="flex gap-2 items-center">
                            {item?.unread > 0 && (
                              <div className="w-2 h-2 bg-green-400 rounded-full"></div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </React.Fragment>
              );
            })}
        </div>
        <div className="w-8/12 h-full ">
        <Outlet />
        </div>
      </div>
    </div>
  );
}

export default ChatIndex;
