import React from 'react'
import UseGetDashBoardData from '../../../../../Components/Hooks/useDashboardData';
import { FaClipboardCheck, FaHourglassHalf } from 'react-icons/fa';

function DeliveryNote() {
    const { data, loading } = UseGetDashBoardData(`/dashboard/delivery-notes-approvals`);

  return (
    <div className="w-full md:w-[33%] px-4">
          <div className="grid items-center justify-center">
            <div className="pl-3">
              <p className="text-[#4F4768] text-xs 2xl:text-sm font-normal">Delivery Note</p>
             <p className='text-[#333333] text-[18px]  font-semibold '>{data?.total || 0}</p>
             <div className="flex gap-2 grid-cols-1">
      <div className="flex items-center text-[12px]">
          <FaHourglassHalf className="text-yellow-500 mr-1" />
          <div className=' w-full flex items-center'>
          <p className='text-xs'>Pending : <span>{data?.pendding || '0'}</span></p>
          </div>
         
        </div>
        <div className="flex items-center  text-[12px]">
          <FaClipboardCheck className="text-purple-500 mr-1" />
          <div className=' w-full flex items-center'>
          <p className=' text-xs '>Verified : <span>{data?.reviewed || '0'}</span></p>
          </div>
          
        </div>
        </div>
            
             </div>
             
          </div>
         
              </div>
  )
}

export default DeliveryNote