import React, { useEffect, useRef, useState } from "react";
import axiosInstance from "../../Services/axiosCommon";
import moment from "moment";
import NuDate from "../../Components/Utils/NuDate";
import logout from "../../Components/Utils/Logout";
import ModalContainer from "../../Components/Others/ModalContainer";
import BellIcon from "../../Components/Icons/BellIcon";
import DecryptData from "../../Components/Utils/Decrypt";
import LanguageSelector from "../../Components/Others/LanguageSelector";
import { Link, useNavigate } from "react-router-dom";
import Dashboard from "../../Components/Icons/Dasboard";
import { useCurrency } from "../../Context/CurrencyContext";
import socketAxiosInstance from "../../Services/socketAxiosCommon";
import { GetDashboardRole, GetRole } from "../../Components/Utils/roles";
import { useToast } from "../../Components/Others/ToastServices";
import Chat from "../../Components/Icons/Chat";
import { useSocket } from "../../Context/SocketContext";

function Navbar() {
  const toast = useToast();
  const userDetails = DecryptData("sLiAtEdReSuEdArT") || "";
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [notifCount, setNotifCount] = useState(0);
  const [msgCount, setMsgCount] = useState(0);
  const [message, setMessage] = useState([]);
  const { updateCurrency } = useCurrency();

  const [open, setOpen] = useState(false);
  const [noti, setNoti] = useState([]);
  const [title, setTitle] = useState({
    Name: "",
    Country: "",
  });

  function getCompanyName() {
    axiosInstance
      .get(`/company-info/1`)
      .then((res) => {
        res.data?.data &&
          setTitle({
            Name: res.data?.data?.companyName,
            Country: res.data.data?.compLogo?.file || "",
          });
        updateCurrency(res.data.data?.currency);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function getNotifications(userId) {
    socketAxiosInstance
      .get(`/notification/${userId}`)
      .then((res) => {
        if (res?.data?.status === true) {
          let data = res?.data?.data;

          data && setNoti(data?.notifications || []);
          data && setNotifCount(data?.count || 0);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function getComments(userId) {
    socketAxiosInstance
      .get(`/notification/comments/${userId}`)
      .then((res) => {
        if (res?.data?.status === true) {
          let data = res?.data?.data;

          data && setMessage(data?.notifications || []);
          data && setMsgCount(data?.count || 0);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    getCompanyName();
    getNotifications(userDetails?.id);
    getComments(userDetails?.id);
  }, []);

  const { connectionOn, handleNotifications, handleActiveUser } = useSocket();

  useEffect(() => {
    if (connectionOn == true) {
      handleNotifications((notification) => {
        if (notification.type == 1) {
          setNotifCount((prev) => prev + 1);
          setNoti((prev) => [notification, ...prev]);
        } else if (notification.type == 2) {
          setMsgCount((prev) => prev + 1);
          setMessage((prev) => [notification, ...prev]);
        }
        toast.open(
          {
            message: "New Notification",
            description: notification?.msg || "",
            name: notification?.name || "",
            date: notification?.date || "",
            time: notification?.time || "",
            type: "notification",
          },
          5000,
          true
        );
      });
    }
  }, [handleNotifications, connectionOn]);

  const [userList, setUserList] = useState([]);

  useEffect(() => {
    if (connectionOn == true && GetRole()) {
      handleActiveUser((userList) => {
        setUserList(userList);
      });
    }
  }, [handleActiveUser, connectionOn]);

  const onClose = () => {
    setOpen(false);
  };

  const Space = ({ children }) => {
    return <div className="flex">{children}</div>;
  };

  const Badge = ({ count, children }) => {
    return (
      <div className="relative inline-block">
        {children}
        {count > 0 && (
          <span className="absolute top-0 p-1 right-0 bg-red-500 text-white w-4 h-4 flex items-center justify-center rounded-full text-[8px]">
            {count}
          </span>
        )}
      </div>
    );
  };

  const Dropdown = ({ overlay, children, trigger }) => {
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);

    useEffect(() => {
      const handleClickOutside = (event) => {
        if (
          dropdownRef.current &&
          !dropdownRef.current.contains(event.target)
        ) {
          setIsOpen(false);
        }
      };

      if (isOpen) {
        document.addEventListener("mousedown", handleClickOutside);
      } else {
        document.removeEventListener("mousedown", handleClickOutside);
      }

      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [isOpen]);

    const toggleDropdown = () => {
      setIsOpen(!isOpen);
    };

    const handleTrigger = () => {
      if (trigger === "click") {
        toggleDropdown();
      }
      // You can add support for other trigger events here if needed
    };

    return (
      <div
        className="relative inline-block"
        onClick={handleTrigger}
        ref={dropdownRef}
      >
        <div>{children}</div>
        {isOpen && (
          <div className="absolute right-[100%] top-[70%] w-48 bg-white rounded-md shadow-lg z-10">
            {overlay}
          </div>
        )}
      </div>
    );
  };

  const getUnreadCount = () => {
    return noti.filter((noti) => !noti.is_read).length;
  };

  function Menu() {
    
    const navigate = useNavigate();
  
    const handleNavigate = (module, id) => {
      switch (module) {
        case "pr":
          navigate(`/procurement/purchase-requests/view/${id}`);
          break;
        case "rfq":
          navigate(`/procurement/rfq/view/${id}`);
          break;
        case "supplier_quotes":
          navigate(`/procurement/rfq/view/${id}`,{state:{active:2}});
          break;
        case "purchase_order":
          navigate(`/procurement/purchase-order/view/${id}`);
          break;
          case "sales_order":
            navigate(`/sales/sales-orders/view/${id}`);
            break;
            case "customer_quotes":
              navigate(`/sales/quotes/view/${id}`);
              break;
              case "supplier":
                navigate(`/procurement/suppliers/view/${id}`);
                break;
        default:
          console.error("Invalid module");
      }
    };
    const handleRead = async (id) => {
          axiosInstance
            .put(`/notification/update/is_read/${id}`, {
              is_read: 1,
            })
            .then((res) => {
              setNoti((prev) =>
                prev.map((noti) =>
                  noti?.notificationId === id ? { ...noti, is_read: true } : noti
                )
              );
              setNotifCount(getUnreadCount());
            })
            .catch((err) => {});
        };
   
  
     function ClearAll() {
      axiosInstance
        .delete(`/notification/delete`)
        .then((res) => {
          setNoti([]);
          setNotifCount(0);
        })
        .catch((err) => {});
    }
  
      function MarkAll() {
      axiosInstance
        .get(`/notification/update/mark_all_as_read`)
        .then((res) => {
          setNoti((prev) =>
            prev.map((noti) => {
              return { ...noti, is_read: true };
            })
          );
          setNotifCount(0);
        })
        .catch((err) => {});
    }
  
    return (
      <div className="w-72 h-96 bg-white shadow-lg rounded-md overflow-hidden">
        <div className="w-full h-10 bg-slate-200 flex justify-between items-center">
          <p className="font-bold ml-2">Notification</p>
          <div className="flex items-center gap-2">
            {notifCount > 0 && (
              <button
                className="outline-none border-none text-xs font-normal px-2 py-1 rounded bg-[#048178] text-white cursor-pointer"
                onClick={MarkAll}
              >
                Mark as Read
              </button>
            )}
            {noti.length > 0 && (
              <button
                className="outline-none border-none text-xs font-normal px-2 py-1 rounded bg-[#048178] text-white cursor-pointer"
                onClick={ClearAll}
              >
                Clear All
              </button>
            )}
          </div>
        </div>
        <div className="w-full h-[90%] overflow-y-scroll">
          {noti.map((notification, idx) => (
            <div
              key={idx}
              className={`${
                notification.is_read ? "bg-white" : "bg-slate-100"
              } w-full my-1 p-1 rounded gap-1 flex justify-between items-center cursor-pointer`}
              onClick={() => {
                handleRead(notification.notificationId);
                handleNavigate(notification.module, notification.id);
              }}
            >
              <div className="px-2 w-[15%]">
                <div className="w-8 h-8 bg-baseColor-primary flex justify-center items-center text-white font-medium text-lg rounded-full overflow-hidden">
                  {notification.sender_imageUrl ? (
                    // <img
                    //   src={notification.sender_imageUrl}
                    //   width="50px"
                    //   alt="Sender"
                    // />
                    <img
                                              src={
                                                notification.sender_imageUrl
                                                  ? notification.sender_imageUrl
                                                  : null
                                              }
                                              width="50px"
                                              alt={`${notification.sender_imageUrl}`}
                                            />
                  ) : (
                    `${notification.name.charAt(0)}`
                  )}
                </div>
              </div>
              <div
                className={`w-[85%] ${
                  notification.is_read ? "font-normal" : "font-medium"
                }`}
              >
                  <p className="pb-1 text-ellipsis text-slate-900 leading-5 text-xs">
                         {notification?.msg}
                       </p>
                       <p className="text-[10px] text-slate-700">
                         By {notification?.name}{" "}
                         {moment(notification?.date).format("DD-MMM-YYYY")} at{" "}
                         <span className="text-[8px] text-slate-700">
                           <NuDate
                             value={`${notification?.date} ${notification?.time}`}
                             format="hh:mm a"
                           />
                         </span>
                       </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  //   const handleRead = async (id) => {
  //     axiosInstance
  //       .put(`/notification/update/is_read/${id}`, {
  //         is_read: 1,
  //       })
  //       .then((res) => {
  //         setNoti((prev) =>
  //           prev.map((noti) =>
  //             noti?.notificationId === id ? { ...noti, is_read: true } : noti
  //           )
  //         );
  //         setNotifCount(getUnreadCount());
  //       });
  //   };

  //   function ClearAll() {
  //     axiosInstance
  //       .delete(`/notification/delete`)
  //       .then((res) => {
  //         setNoti([]);
  //         setNotifCount(0);
  //       })
  //       .catch((err) => {});
  //   }

  //   function MarkAll() {
  //     axiosInstance
  //       .get(`/notification/update/mark_all_as_read`)
  //       .then((res) => {
  //         setNoti((prev) =>
  //           prev.map((noti) => {
  //             return { ...noti, is_read: true };
  //           })
  //         );
  //         setNotifCount(0);
  //       })
  //       .catch((err) => {});
  //   }

  //   return (
  //     <>
  //       <div className="w-72 h-96  bg-white shadow-lg rounded-md overflow-hidden">
  //         <div className=" w-full h-10 bg-slate-200 flex justify-between items-center">
  //           <p className="font-bold ml-2">Notification</p>
  //           <div className=" flex items-center gap-2">
  //             {notifCount > 0 ? (
  //               <button
  //                 className=" outline-none border-none text-xs font-normal px-2 py-1 rounded bg-mainColor-300 text-white cursor-pointer bg-[#048178]"
  //                 onClick={MarkAll}
  //               >
  //                 Marks as read
  //               </button>
  //             ) : null}
  //             {noti.length > 0 ? (
  //               <button
  //                 className=" outline-none border-none text-xs font-normal px-2 py-1 rounded bg-mainColor-300 text-white cursor-pointer bg-[#048178]"
  //                 onClick={ClearAll}
  //               >
  //                 Clear All
  //               </button>
  //             ) : null}
  //           </div>
  //         </div>
  //         <div className="w-full h-[90%] overflow-y-scroll">
  //           {noti?.map((notification, idx) => {
  //             return (
  //               <>
  //                 <div
  //                   className={`${
  //                     notification?.is_read ? "bg-white" : " bg-slate-100"
  //                   } w-full my-1 p-1 rounded gap-1 flex justify-between items-center cursor-pointer`}
  //                   onClick={() => handleRead(notification?.notificationId)}
  //                 >
  //                   <div className="px-2 w-[15%]">
  //                     {/* <NuAvatarGroup userdetails={[{name:`${notification?.sender_fName}`,imageUrl:`${notification?.sender_imageUrl}`}]} />    */}
  //                     <div className="w-8 h-8 bg-baseColor-primary  flex justify-center items-center text-white font-medium text-lg rounded-full overflow-hidden">
  //                       {notification.sender_imageUrl ? (
  //                         <img
  //                           src={
  //                             notification.sender_imageUrl
  //                               ? notification.sender_imageUrl
  //                               : null
  //                           }
  //                           width="50px"
  //                           alt={`${notification.sender_imageUrl}`}
  //                         />
  //                       ) : (
  //                         `${notification?.name?.charAt(0)}`
  //                       )}
  //                     </div>
  //                   </div>
  //                   <div
  //                     className={`w-[85%] ${
  //                       notification?.is_read
  //                         ? " font-normal "
  //                         : "font-medium  "
  //                     }`}
  //                   >
  //                     {/* <p className='font-semibold'>Admin <span className='font-normal'>to</span> Login Person</p> */}
  //                     <p className="pb-1 text-ellipsis text-slate-900 leading-5 text-xs">
  //                       {notification?.msg}
  //                     </p>
  //                     <p className="text-[10px] text-slate-700">
  //                       By {notification?.name}{" "}
  //                       {moment(notification?.date).format("DD-MMM-YYYY")} at{" "}
  //                       <span className="text-[8px] text-slate-700">
  //                         <NuDate
  //                           value={`${notification?.date} ${notification?.time}`}
  //                           format="hh:mm a"
  //                         />
  //                       </span>
  //                     </p>
  //                   </div>
  //                 </div>
  //               </>
  //             );
  //           })}
  //         </div>
  //       </div>
  //     </>
  //   );
  }
  

  function Message() {
    const handleRead = async (id) => {
      axiosInstance
        .put(`/notification/update/is_read/${id}`, {
          is_read: 1,
        })
        .then((res) => {
          setNoti((prev) =>
            prev.map((noti) =>
              noti?.notificationId === id ? { ...noti, is_read: true } : noti
            )
          );
          setNotifCount(getUnreadCount());
        });
    };

    function ClearAll() {
      axiosInstance
        .delete(`/delete/comments`)
        .then((res) => {
          setMessage([]);
          setMsgCount(0);
        })
        .catch((err) => {});
    }

    function MarkAll() {
      axiosInstance
        .get(`/update/comments/mark_all_as_read`)
        .then((res) => {
          setNoti((prev) =>
            prev.map((noti) => {
              return { ...noti, is_read: true };
            })
          );
          setNotifCount(0);
        })
        .catch((err) => {});
    }

    return (
      <>
        <div className="w-72 h-96  bg-white shadow-lg rounded-md overflow-hidden">
          <div className=" w-full h-10 bg-slate-200 flex justify-between items-center">
            <p className="font-bold ml-2">Comments</p>
            <div className=" flex items-center gap-2">
              {msgCount > 0 ? (
                <button
                  className=" outline-none border-none text-xs font-normal px-2 py-1 rounded bg-mainColor-300 text-white cursor-pointer bg-[#048178]"
                  onClick={MarkAll}
                >
                  Marks as read
                </button>
              ) : null}
              {message.length > 0 ? (
                <button
                  className=" outline-none border-none text-xs font-normal px-2 py-1 rounded bg-mainColor-300 text-white cursor-pointer bg-[#048178]"
                  onClick={ClearAll}
                >
                  Clear All
                </button>
              ) : null}
            </div>
          </div>
          <div className="w-full h-[90%] overflow-y-scroll">
            {message?.map((notification, idx) => {
              return (
                <>
                  <div
                    className={`${
                      notification?.is_read ? "bg-white" : " bg-slate-100"
                    } w-full my-1 p-1 rounded gap-1 flex justify-between items-center cursor-pointer`}
                    onClick={() => handleRead(notification?.notificationId)}
                  >
                    <div className="px-2 w-[15%]">
                      {/* <NuAvatarGroup userdetails={[{name:`${notification?.sender_fName}`,imageUrl:`${notification?.sender_imageUrl}`}]} />    */}
                      <div className="w-8 h-8 bg-baseColor-primary  flex justify-center items-center text-white font-medium text-lg rounded-full overflow-hidden">
                        {notification.sender_imageUrl ? (
                          <img
                            src={
                              notification.sender_imageUrl
                                ? notification.sender_imageUrl
                                : null
                            }
                            width="50px"
                            alt={`${notification.sender_imageUrl}`}
                          />
                        ) : (
                          `${notification?.name?.charAt(0)}`
                        )}
                      </div>
                    </div>
                    <div
                      className={`w-[85%] ${
                        notification?.is_read
                          ? " font-normal "
                          : "font-medium  "
                      }`}
                    >
                      {/* <p className='font-semibold'>Admin <span className='font-normal'>to</span> Login Person</p> */}
                      <p className="pb-1 text-ellipsis text-slate-900 leading-5 text-xs">
                        {notification?.msg}
                      </p>
                      <p className="text-[10px] text-slate-700">
                        By {notification?.name}{" "}
                        {moment(notification?.date).format("DD-MMM-YYYY")} at{" "}
                        <span className="text-[8px] text-slate-700">
                          <NuDate
                            value={`${notification?.date} ${notification?.time}`}
                            format="hh:mm a"
                          />
                        </span>
                      </p>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </>
    );
  }

  const handleLogOut = async () => {
    setIsModalOpen(true);
  };

  function OnlineUsers({ list = [] }) {
    return (
      <>
        <div className="w-72 h-96 relative  bg-white shadow-lg rounded-md overflow-y-auto overflow-x-hidden px-2">
          <p className=" w-full sticky top-0 py-1 bg-white font-semibold text-xs 2xl:text-sm text-gray-900">
            Online User
          </p>
          {list &&
            list.map((user, index) => (
              <div
                className=" w-full flex justify-start items-center gap-x-3 my-1"
                key={index}
              >
                <div className=" w-[45px] h-[45px] rounded-full text-white  flex justify-center overflow-hidden items-center bg-baseColor-primary border border-white">
                  {user.image != "" && user.image != null ? (
                    <>
                      <img
                        src={user.image}
                        alt="#"
                        height={"45px"}
                        width="auto"
                      />
                    </>
                  ) : (
                    <>{getInitials(user?.name || "")}</>
                  )}
                </div>
                <p className=" text-gray-900 font-medium text-xs 2xl:text-sm">
                  {user?.name}
                </p>
              </div>
            ))}
        </div>
      </>
    );
  }

  const getInitials = (name) => {
    const names = name.split(" ");
    if (names.length > 1) {
      return names[0][0] + names[1][0];
    }
    return names[0][0];
  };

  return (
    <>
      <style jsx>{`
        .circle-container .circle {
          position: relative;
          display: inline-block;
        }

        .circle-container .circle .circle-tooltip {
          visibility: hidden;
          max-width: 250px;
          height:16px;
          background-color: #fff;
          color: #555;
          text-align: center;
          border-radius: 5px;
          padding: 5px;
          position: absolute;
          z-index: 20;
          top: 100%; /* Position the tooltip above the circle */
          left: 50%;
          transform: translateX(-50%);
          opacity: 0;
          transition: opacity 0.3s;
          border: 1px solid #048178;
        }

        .circle-container .circle:hover .circle-tooltip {
          visibility: visible;
          opacity: 1;
        }
      `}</style>
      <nav className=" w-full flex justify-between">
        <div className=" w-4 lg:w-6/12 h-full p-3">
          <div className=" hidden h-full items-center lg:flex">
            {
              <div className=" flex justify-center items-center gap-2">
                {title.Country && (
                  <div className="max-w-[150px] h-10 overflow-hidden flex justify-center items-center">
                    <img
                      src={title.Country}
                      style={{ height: "40px", width: "auto" }}
                      alt="#fff"
                    />
                  </div>
                )}
                <span className=" text-base capitalize font-medium">
                  {title.Name || " "}
                </span>
              </div>
            }
          </div>
          {/* <div className=" lg:hidden cursor-pointer" onClick={showDrawer}>
        <MenuOutlined />
      </div> */}
        </div>
        {GetRole() && (
          <>
            <div className=" w-[65%] h-[60px] mr-2 flex justify-end items-center">
              <div
                className="circle-container"
                style={{ display: "flex", alignItems: "center" }}
              >
                {userList && userList.length > 0 ? (
                  userList.slice(0, 10).map((name, index) => (
                    <React.Fragment key={index}>
                      {index == 9 ? (
                        <>
                          <div
                            className="circle text-xs"
                            style={{
                              width: "25px",
                              height: "25px",
                              borderRadius: "50%",
                              backgroundColor: "#f87171",
                              color: "white",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              fontWeight: "normal",
                              marginLeft: index !== 0 ? "-10px" : "0", // Add overlap effect
                              position: "relative",
                              zIndex: 1 + index, // Stack circles on top of each other
                              transition: "all 0.3s ease",
                              cursor: "pointer",
                              border: "1px solid #fff",
                            }}
                          >
                            <div className="">
                              <Dropdown
                                overlay={<OnlineUsers list={userList} />}
                                trigger="click"
                              >
                                <div className=" w-full h-full text-xs whitespace-nowrap">
                                  10+
                                </div>
                              </Dropdown>
                            </div>
                          </div>
                        </>
                      ) : (
                        <div
                          className="circle text-xs"
                          style={{
                            width: "25px",
                            height: "25px",
                            borderRadius: "50%",
                            backgroundColor: "#048178",
                            color: "white",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            fontWeight: "normal",
                            marginLeft: index !== 0 ? "-10px" : "0", // Add overlap effect
                            position: "relative",
                            zIndex: 1 + index, // Stack circles on top of each other
                            transition: "all 0.3s ease",
                            cursor: "pointer",
                            border: "1px solid #fff",
                          }}
                        >
                          {name.image != "" && name.image != null ? (
                            <div className=" w-[25px] h-[25px] rounded-full flex justify-center items-center overflow-hidden">
                              <img
                                src={name.image}
                                alt="#"
                                className=" w-auto h-[35px] object-cover"
                              />
                            </div>
                          ) : (
                            <>{getInitials(name?.name || "")}</>
                          )}
                          <div className="circle-tooltip ">
                            <div className=" -mt-[5px] text-xs whitespace-nowrap">
                              {name?.name || "-"}
                            </div>
                          </div>
                        </div>
                      )}
                    </React.Fragment>
                  ))
                ) : (
                  <p> </p>
                )}
              </div>
            </div>
          </>
        )}
        <div className=" flex justify-center items-center">
          <div className="px-0.5 mr-2 bg-slate-100 h-7 rounded-lg flex justify-center items-center  cursor-pointer">
            <Link
              to={
                GetDashboardRole("")
                  ? "/dashboard"
                  : GetDashboardRole("sales")
                  ? "/dashboard/sales"
                  : GetDashboardRole("procurement")
                  ? "/dashboard/procurement"
                  : GetDashboardRole("store")
                  ? "/dashboard/stores"
                  : "/dashboard"
              }
            >
              <Dashboard />
            </Link>
          </div>
          <div className="mr-2">
            <Space>
              <Badge count={msgCount}>
                <Dropdown overlay={<Message />} trigger="click">
                  <div className=" px-1  flex justify-center items-center  cursor-pointer bg-slate-100  h-7 rounded-lg">
                    <Chat color="#1C274C" />
                  </div>
                </Dropdown>
              </Badge>
            </Space>
          </div>
          <div className="mr-2">
            <Space>
              <Badge count={notifCount}>
                <Dropdown overlay={<Menu />} trigger="click">
                  <div className=" px-1  flex justify-center items-center  cursor-pointer bg-slate-100  h-7 rounded-lg">
                    <BellIcon />
                  </div>
                </Dropdown>
              </Badge>
            </Space>
          </div>

          <div class=" md:p-2 flex items-center">
            <div className="dropdown inline-block relative">
              <button className=" text-gray-700 outline-none border-none bg-transparent font-semibold md:px-4 flex items-center">
                <svg
                  className="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />{" "}
                </svg>
                <div className=" relative w-9 h-9 mx-2 rounded-full bg-white overflow-hidden">
                  <div className="absolute z-10 bg-transparent top-0 left-0 w-full h-9 border-4 rounded-full border-solid border-[#016c62]"></div>
                  <div className="absolute z-20 bottom-0 right-0 bg-transparent w-1/2 h-9 border-8 rounded-r-full border-solid border-white -[#F4F2F8]"></div>
                  <div className="absolute z-30 w-full [calc(36px-4px)] h-full [calc(36px-4px)] flex justify-center text-slate-900 capitalize items-center bg-transparent">
                    {userDetails.image
                      ? userDetails.image
                      : userDetails?.name?.charAt(0)}
                  </div>
                </div>
                <div className=" hidden md:flex flex-col leading-none">
                  <p className="text-sm font-semibold">
                    {userDetails?.name || " "}
                    {/* {userDetails?.userLastName || " "} */}
                  </p>
                </div>
              </button>
              <ul className="dropdown-menu pt-2 leading-none z-50 absolute hidden shadow-sm">
                <li className="list-none">
                  <Link
                    to={`/profile`}
                    className="rounded-t bg-baseColor-teritary font-medium hover:bg-baseColor-quartary py-2  px-4 block whitespace-no-wrap"
                    href="#"
                  >
                    Profile
                  </Link>
                </li>

                <li className="list-none cursor-pointer">
                  <div
                    className="rounded bg-baseColor-teritary font-medium hover:bg-baseColor-quartary py-2  px-4 block whitespace-no-wrap"
                    href="#"
                    onClick={handleLogOut}
                  >
                    Log Out
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <ModalContainer
          visiable={isModalOpen}
          title="Want to Logout?"
          content="Are you sure to logout from this app!"
          handleClick={() => logout()}
          closeModal={() => setIsModalOpen(false)}
        />
      </nav>
    </>
  );
}

export default Navbar;
