import React, { useState } from 'react';
import { CancelButton, SubmitButton } from '../../../../Components/Utils/Buttons';
import MultiSelect from '../../../../Components/Utils/MultiSelect';
import axiosInstance from '../../../../Services/axiosCommon';
import { useFormik } from 'formik';
import useFetchMataData from '../../../../Components/Hooks/useFetchMataData';
import * as yup from 'yup';
import UseDropDown from '../../../../Components/Hooks/useDropDown';
import { useToast } from '../../../../Components/Others/ToastServices';

function MultiEdit({ onClose, data, getData }) {
    const toast = useToast();

  const { data: user } = UseDropDown("/user/drop-down");
  const { value: Status } = useFetchMataData(`/dropdown/dropdown-details/`, 'status_RFQ');
  const [createNew, setCreateNew] = useState({
    users: [],
    ids: [],
    isGeneral:null
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: createNew,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object({
      // Add your validation schema here
    }),
    onSubmit: async (value, { resetForm }) => {
      value.ids = data;
      axiosInstance.put(`/vendors/edit-access`, value)
        .then(res => {
          if (res.data?.status === true) {
            toast.open({
              type: "success",
              message: "Success",
              description: res.data?.msg || "Created Successfully."
            });
            getData();
            onClose();
          } else if (res.data?.status === false && res.data?.error) {
            toast.open({
              type: "error",
              message: "Error",
              description: res.data.error || "Network error."
            });
          } else {
            toast.open({
              type: "warning",
              message: "Warning",
              description: res.data?.msg || "Warning."
            });
          }
        })
        .catch(err => {
          console.error(err);
          toast.open({
            type: "error",
            message: "Error",
            description: "Network error."
          });
        });
    }
  });


  function closeFilterDialog() {
    formik.resetForm();
    onClose();
  }

  const handleChange = (event) => {
    const value = event.target.value === "true" ? true : event.target.value === "false" ? false : null;
    formik.setFieldValue("isGeneral", value);
  };

  const handleClear = () => {
    formik.setFieldValue("isGeneral", null); 
  };
  return (
    <div className="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-50 z-[9998] flex justify-center items-center">
      <div className="animate-scale-in min-w-[40%] max-w-[50%] max-h-[80vh] relative bg-white py-4 px-6 rounded-md shadow-lg overflow-y-scroll">
        <div className="w-full flex justify-between items-center">
          <h2 className="text-sm font-medium mb-4">Edit Access</h2>
          <button
            className="absolute top-2 right-2 text-white bg-red-600"
            onClick={onClose}
          >
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
        <form onSubmit={formik.handleSubmit}>
          <div className="mb-4">
            <MultiSelect
              label="Users"
              placeholder="Choose"
              Options={user}
              formik={formik}
              name="users"
              // isRequired={true}
              width="w-full"
              FixPosition={true}

            />
 <div className="w-full px-6 md:w-1/2 lg:w-1/3">
              <h2 className="text-sm font-normal mb-2 mt-4">Access</h2>
              <div className='flex gap-8'>
                <div className="flex items-center ">
                  <input
                    type="radio"
                    name="isGeneral"
                    value="true"
                    checked={formik.values.isGeneral === true}
                    onChange={handleChange}
                  />
                  <label className="ml-2 text-sm">Public</label>
                </div>
                <div className="flex items-center ">
                  <input
                    type="radio"
                    name="isGeneral"
                    value="false"
                    checked={formik.values.isGeneral === false}
                    onChange={handleChange}
                  />
                  <label className="ml-2 text-sm">Private</label>
                </div>
                {(formik.values.isGeneral === true || formik.values.isGeneral === false) && (
                <button
                  type="button"
                  className="text-xs text-[#048178] border border-[#048178] rounded-lg p-0.5"
                  onClick={handleClear}
                >
                  Clear
                </button>
              )}
              </div>
            </div>
          </div>

        
          <div className="flex justify-end gap-2">
            <CancelButton handleClick={closeFilterDialog} />
            <SubmitButton name="Submit" />
          </div>
        </form>
      </div>
    </div>
  );
}

export default MultiEdit;