// socketService.js
import { io } from 'socket.io-client';
import DecryptData from '../Components/Utils/Decrypt';

const SOCKET_URL = window.SOCKET_SERVER_URL;
let socket;

const userDetails = DecryptData("sLiAtEdReSuEdArT")||"";
const id = userDetails?.id||""; 
const userEmail = userDetails?.email||"";

const joinData = {
  userId: id,
  username: userEmail,
};

export const initiateSocketConnection = () => {
  if (id && userEmail) {
    if (!socket || !socket.connected) {
      socket = io(`${SOCKET_URL}?user_id=${id}`, {
        transports: ["websocket"],
        reconnection: true,
        reconnectionAttempts: 5,
        reconnectionDelay: 1000,
      });
      console.log("Connecting to socket...");
      socket.on("connect", () => {
        console.log("Socket connected:", socket.id);
        socket.emit("join_notif_room", joinData);
        socket.emit("join_room", joinData);
      });

      socket.on("connect_error", (error) => {
        console.error("Socket connection error:", error);
      });

      socket.on("disconnect", () => {
        console.log("Socket disconnected.");
      });
    }
  } else {
    console.error("User details are missing, cannot initiate socket connection.");
  }
};

export const disconnectSocket = () => {
  if (socket) {
    console.log("Disconnecting socket...");
    socket.emit("leave_notif_room", joinData);
    socket.disconnect();
    socket = null;
  }
};

export const subscribeToEvent = (eventName, callback) => {
  if (!socket) {
    console.error("Socket is not connected, cannot subscribe to event:", eventName);
    return;
  }
  socket.on(eventName, callback);
  console.log("Subscribed to event:", eventName);
};

export const unsubscribeFromEvent = (eventName) => {
  if (!socket) {
    console.error("Socket is not connected, cannot unsubscribe from event:", eventName);
    return;
  }
  socket.off(eventName);
  console.log("Unsubscribed from event:", eventName);
};

export const emitEvent = (eventName, data) => {
  if (!socket) {
    console.error("Socket is not connected, cannot emit event:", eventName);
    return;
  }
  socket.emit(eventName, data);
  console.log(`Event emitted: ${eventName}, Data:`, data);
};

export const handleNotifications = async (callback) => {
  if (!socket) {
    console.error("Socket is not connected, cannot handle notifications.");
    return;
  }

   subscribeToEvent('receive_notification', (notification) => {
    if (notification) {
      console.log("Notification received:", notification);
      callback(notification); // Pass the notification to the callback
    } else {
      console.warn("Received an empty or invalid notification.");
    }
  });
};

export const handleActiveUser = async (callback) => {
  if (!socket) {
    console.error("Socket is not connected, cannot handle notifications.");
    return;
  }

   subscribeToEvent('active_users', (activeUser) => {
    if (activeUser) {
      console.log("new list", activeUser)
      callback(activeUser); // Pass the notification to the callback
    } else {
      console.warn("Received an empty or invalid notification.");
    }
  });
};


export const handleUnreadMessages = (notification) => {  
  if (!socket) {
    console.error("Socket is not connected, cannot handle notifications.");
    return;
  }
  subscribeToEvent('unread_message', notification);
};

export const sendMessage = (message) => {
  emitEvent('send_message', message);
};

export const ReceivedMessage = (callback) => {
  if (!socket) {
    console.error("Socket is not connected, cannot handle notifications.");
    return;
  }

   subscribeToEvent('receive_message', (message) => {
    if (message) {
      callback(message); // Pass the notification to the callback
    } else {
      console.warn("Received an empty or invalid notification.");
    }
  });
}

export const sendNotification = (notification) => {
  console.log("send to server",notification);
  
  emitEvent('send_notification', notification);
};
