import React, { useEffect, useRef, useState } from 'react'
import Send from '../../../Components/Icons/send';
import { useParams } from 'react-router-dom';
import { useSocket } from '../../../Context/SocketContext';
import DecryptData from '../../../Components/Utils/Decrypt';
import axiosInstance from '../../../Services/axiosCommon';
import DateFormat from "../../../Components/Utils/NuDate";

function ChatProfile() {
  const { chatID } = useParams();
  const {sendChatMessage,ReceivedMessage} = useSocket()
  const [message, setMessage] = useState("")
  const [messages, setMessages] = useState([])
  const [item, setItem] = useState([])
  const messageContainerRef = useRef(null);
  
  const userDetails = DecryptData("sLiAtEdReSuEdArT") || "";

  const handleSendMessage = (e) => {
    e.preventDefault()
    if (message === "" || message === null) {
      return
    }
    const currentDate = new Date();
    const formattedDate = currentDate.toLocaleDateString('en-US', {
      year: '2-digit',
      month: 'short', // Use 'short' for abbreviated month names
      day: '2-digit',
    });
    const formattedTime = currentDate.toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    });
    if (chatID){
      const newMsg = {
        sender_id: userDetails.id,
        receiver_id: chatID,
        username: userDetails.email,
        message: message,
        time: formattedTime,
        date: formattedDate
      }
      sendChatMessage(newMsg)
      setMessages([...messages, newMsg])
      setMessage("")
    }
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage(e);
    }
  };

  async function getMessages(id) {
    await axiosInstance
      .get(`/chat/`, {
        params: {
          sender_id: userDetails?.id || 1,
          receiver_id: id || 2,
        },
      })
      .then(async (res) => {
        (await res?.data?.data) &&
          setMessages(res?.data?.data);
        // setTotalProject(res.data?.data?.totalRecords);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function getUserDetail(id){
    await axiosInstance
    .get(`/user/user-details/${id}`)
    .then(async (res) => {
      (await res?.data?.data) &&
        setItem(res?.data?.data);
    })
    .catch((error) => {
      console.log(error);
    });
  }


    useEffect(() => {
      setMessages([])
      setMessage("")
      if(chatID){
        getUserDetail(chatID)
        getMessages(chatID);

      }
    }, [chatID])

    useEffect(() => {
      console.log("id-user outer",chatID);
      if (item?.userId) {
        ReceivedMessage((msg)=>{
          console.log("id-user inner",item?.userId);
          if (msg?.sender_id == item?.userId && msg.receiver_id == userDetails?.id) {
            console.log("working",msg?.sender_id,item?.userId,msg?.sender_id == item?.userId);
          
            setMessages((prevMessages) => [...prevMessages, msg]);
          }
        })
        // socket.on("receive_message", handleMessageReceive);
      }
    }, [item,ReceivedMessage])

    useEffect(() => {
      messageContainerRef.current.scrollTop = messageContainerRef.current.scrollHeight;
    }, [messages]);

  return (
    <div className="w-full h-full rounded-r-md overflow-hidden">
      <div className='w-full h-[90%] overflow-hidden'>
        <div className='w-full h-14 bg-baseColor-secondary pt-2 px-2 mb-3'>
          <div className='flex justify-between items-center'>
            <div className='flex gap-2 item-center'>
              <div className="w-9 h-9 bg-teal-100 rounded-full overflow-hidden mt-0.5 flex items-center justify-center border border-white border-solid">
                {item?.image ? (
                  <img
                    src={item.image}
                    width="50px"
                    alt={`${item.image}`}
                    className="max-w-full max-h-full"
                  />
                ) :
                  <h1 className='font-semibold text-textColor-main text-xl'>
                    {item?.name ? item?.name?.[0] : null}
                  </h1>}
              </div>
              <div className="text-base font-medium text-[#ffffff] mt-2">
                {item?.name||"-"}
              </div>
            </div>
          </div>
        </div>
                <div ref={messageContainerRef} className='w-full max-h-[calc(97%-3.5rem)] overflow-y-auto p-2'>
                  {
                     messages.map((iteem, idx) => {    
                                
                      return (

                        <div className='w-full my-2' key={idx}>
                          {
                           <>
                            {
                              userDetails?.id !== iteem.sender_id ?
                                <div className='flex justify-start'>
                                  <div className='rounded-md min-w-[120px] px-2 py-0.5 bg-teal-200  border border-teal-400 text-gray-500 rounded-e-xl rounded-es-xl'>
                                    <pre>{iteem.message}</pre>
                                    <p className='text-[9px] text-gray-400 text-end'>{iteem?.date == DateFormat({value:Date(),format:"DD-MMM-YY"})?"Today ":iteem?.date} {iteem.time}</p>
                                  </div>
                                </div>
                                :
                                <div className='flex justify-end '>
                                  <div className=' px-2 py-0.5 min-w-[120px] border border-teal-400 text-gray-600 rounded-s-xl rounded-se-xl'>
                                    <pre>{iteem.message}</pre>
                                    <p className='text-[9px] text-gray-400 text-end'>{iteem?.date == DateFormat({value:Date(),format:"DD-MMM-YY"})?"Today ":iteem?.date} {iteem.time}</p>
                                  </div>
                                </div>
                            }
  
                            </>
                          }                         
                        </div>
                      )
                    })
                  }
                </div>
      </div>
      <div className='w-full h-[10%] px-1'>
      <form className='form text-left flex' onSubmit={handleSendMessage}>
          <textarea
            type="text"
            placeholder='Write message'
            className='w-full bg-transparent border-[1px] outline-none border-slate-300 resize-none text-xs text-gray-800 placeholder-gray-500 h-10  p-2 rounded-l-md'
            style={{ resize: 'none' }}
            value={message}
            onChange={e => setMessage(e.target.value)}
            onKeyDown={handleKeyDown}
          />
          <button className={`bg-baseColor-secondary ${message && "cursor-pointer"} p-2 border-none rounded-r-lg`}>
            <Send />
          </button>
        </form>
      </div>
    </div>
  )
}

export default ChatProfile;