import React, { createContext, useContext, useEffect, useState } from 'react';
import {
  initiateSocketConnection,
  disconnectSocket,
  handleNotifications,
  handleUnreadMessages,
  sendMessage,
  sendNotification,
  ReceivedMessage,
  handleActiveUser
} from './socketService';

const SocketContext = createContext();

export const useSocket = () => useContext(SocketContext);

export const SocketProvider = ({ children }) => {
  const [connectionOn, setConnectionOn] = useState(false);

  useEffect(() => {

    initiateSocketConnection();
    setConnectionOn(true);
    return () => {
      disconnectSocket();
      setConnectionOn(false);
    };
  }, []);

  const sendChatMessage = (message) => sendMessage(message);

  
  const sendProfileNotification = (notification) =>{
    sendNotification(notification);
    console.log("Noti",notification);
    
  } 
  const value = {
    sendChatMessage,
    sendProfileNotification,
    handleNotifications,
    handleUnreadMessages,
    connectionOn,
    ReceivedMessage,
    handleActiveUser
  };

  return <SocketContext.Provider value={value}>{children}</SocketContext.Provider>;
};