import { useState } from 'react';
import axiosInstance from '../../Services/axiosCommon';
import { useNavigate } from 'react-router-dom';
import { useToast } from '../Others/ToastServices';
import { useSocket } from '../../Context/SocketContext';

const UseEditData = () => {
  const [isLoading, setIsLoading] = useState(false);
  const {sendProfileNotification}  =useSocket();
  const toast = useToast();
  const navigate = useNavigate()

  function Oncancel() {
      navigate(-1);
  }

  const editData = async (url, value, resetForm, navigateUrl) => {
    setIsLoading(true);

    try {
      const res = await axiosInstance.put(url, value);
      if (res.data?.status === true) {
        if (res.data.notification!==null || res.data.notification!==undefined || res.data.notification!==""){
          sendProfileNotification({...res.data.notification,type:1});
        }
        toast.open({
          type:"success",
          message:"Success",
          description: res.data?.msg || "Created Successfully."
        })
        resetForm();
        navigate(`${navigateUrl}`, { state: { data : res.data?.data } });
      }
      else if(res.data?.status === false && res.data?.error){
        toast.open({
          type:"error",
          message:"Error",
          description: res.data.error || "Network error."
        })
      } 
       else {
        toast.open({
          type:"warning",
          message:"Warning",
          description: res.data?.msg || "Warning."
        })
      }
    } catch (err) {
      console.log(err);
      toast.open({
        type:"error",
        message:"Error",
        description:  "Network error."
      })
    } finally {
      setIsLoading(false);
    }
  };

  return { editData, isLoading, Oncancel };
};

export default UseEditData;