import React, { useEffect, useMemo, useState } from "react";
import {
  BackButton,
  CancelButton,
  SubmitButton,
} from "../../../../Components/Utils/Buttons";
import {
  NuDate,
  NuInput,
  NuMobileNumber,
  NuTextArea,
} from "../../../../Components/Utils/Input";
import FormikSelectTwo, {
  FormikSelectWithAdd,
} from "../../../../Components/Utils/Select";
import Breadcrumb from "../../../../Components/Utils/Breadcrumb";
import { useFormik } from "formik";
import UseAddData from "../../../../Components/Hooks/useAddData";
import UseDropDown from "../../../../Components/Hooks/useDropDown";
import * as yup from "yup";
import Delete from "../../../../Components/Icons/Delete";
import axiosInstance from "../../../../Services/axiosCommon";
import useQuotProducts from "./Hooks/useMemo";
import useFetchMataData from "../../../../Components/Hooks/useFetchMataData";
import { TextMediumBase } from "../../../../Components/Text/MediumText";
import DropDownAddMetaData from "../../../../Components/Utils/selectAddMetaData";
import NuAmount from "../../../../Components/Utils/NuAmount";
import { CountryMIN } from "../../../../assets/CountryMini";
import UseCustomerDropDownNew from "../../../../Components/Hooks/useCustomerSearch";

function CreatePo() {
  const currentDate = new Date().toISOString().split("T")[0];
  const { value: paymentStatus, getData: refpaymentStatus } = useFetchMataData(
    `/dropdown/dropdown-details/`,
    "paymentStatus_po"
  );
  const { value: ShippingMethod, getData: refShippingMethod } =
    useFetchMataData(`/dropdown/dropdown-details/`, "ShippingMethod");
  const { value: DeliveryTerms, getData: refDeliveryTerms } = useFetchMataData(
    `/dropdown/dropdown-details/`,
    "DeliveryTerms_Supplierqoutes"
  );
  const { data: PR } = UseDropDown("/PR/drop-down");
  // const { data: Agents, getData: getAgents } = UseDropDown("/agents/drop-down");
  const [searchAgent, setSearchAgent] = useState("");
  const { data: Agents, setDataCustomer } = UseCustomerDropDownNew(
    "/agents/drop-down",
    searchAgent
  );
  const { value: Type, getData: refType } = useFetchMataData(
    `/dropdown/dropdown-details/`,
    "AgentType"
  );
  const { Oncancel, addData, isLoading } = UseAddData();
  const [items, setItems] = useState([]);
  const [newItemData, setNewItemData] = useState({
    //productName?
    productId: "",
    requestedQty: "",
    orderedQty: "",
    quoteProductId: "",
    price: "",
    product_name: "",
    quantity: "",
    negotiatedPrice: "",
  });


  const handleDeleteItem = (indexToDelete) => {
    // 1. Update the prlist state using filter:
    const updatedPrlist = product.filter((_, index) => index !== indexToDelete);
    console.log("Updated prlist after filter:", updatedPrlist); // Check if item is removed
    setProducts(updatedPrlist);

    // 2. Update the items state based on updated prlist:
    const updatedItems = updatedPrlist.map((item) => ({
      quoteProductId: item.quoteProductId,
      orderedQty: item.orderedQty,
      requestedQty: item.requestedQty,
      price: item.price,
      negotiatedPrice: item.negotiatedPrice,

      unit: item.unit,
    }));
    setItems(updatedItems);

    // 3. Update the totalPrice state based on removed item's price and quantity:
    const removedItemPrice = parseFloat(
      product[indexToDelete].negotiatedPrice || 0
    );
    const removedItemQuantity = parseFloat(
      product[indexToDelete].orderedQty || 0
    );
    const newTotalPrice =
      formik.values.totalPrice - removedItemPrice * removedItemQuantity;

    // 4. Update the formik state for totalPrice using setFieldValue:
    formik.setFieldValue("totalPrice", newTotalPrice);
    console.log("Updated totalPrice:", newTotalPrice);
  };

  const [error, setError] = useState([]);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      prId: "",
      status: "",
      vendorId: "",
      refNumber: "",
      paymentStatus: "",
      comments: "",
      requestedDeliveryDate: currentDate || "",
      approvalStatus: "",
      totalPrice: 0.0,
      paymentTerms: "",
      deliveryTerms: "",
      bookingEnabled: "",
      bookingDate: currentDate || "",
      trackingUrl: "",
      shipmentBill: "",
      agent:"",
      ETD: "",
      companyName:"",
      ETA: "",
      permitReq: false,
      modeOfShipment: "",
      ESC: "",
      ASC: "",
      billingAddressLine1: "",
      billingAddressLine2: "",
      billing_city: "",
      billing_country: "",
      billing_postal_code: "",
      billing_state: "",
      deliveryAddressLine1: "",
      deliveryAddressLine2: "",
      delivery_city: "",
      delivery_country: "",
      delivery_postal_code: "",
      delivery_state: "",
    },
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object({
      prId: yup.string().required("YPR is required!"),
      sqId: yup.string().required("Supplier Quotes is required!"),
      vendorId: yup.string().required("Supplier is required!"),
    }),
    onSubmit: async (value, { resetForm }) => {
      // setLoading(true);
      if (product.length === 0) {
        setError("Product table is empty");
        return;
      }
      const valueWithItems = {
        ...value,
        products: product,
      };
      addData("/PO/new-PO", valueWithItems, resetForm);
      //  setLoading(false)
    },
  });

  const quotProducts = useQuotProducts(
    formik.values.prId,
    formik.values.vendorId
  );

  console.log(quotProducts, "quotes");

  useMemo(() => {
    if (formik.values.quoteProductId !== "") {
      let tem_product = quotProducts.find(
        (i) => i.quoteProductId == formik.values.quoteProductId
      );
      formik.values.price = tem_product && tem_product.price;
      formik.values.negotiatedPrice =
        tem_product && tem_product.negotiatedPrice;

      formik.values.productId = tem_product && tem_product.productId;
      formik.values.requestedQty = tem_product && tem_product.requestedQty;
      formik.values.product_name = tem_product && tem_product.productName;
      formik.values.orderedQty = tem_product && tem_product.orderedQty;

      console.log(tem_product);
    }
  }, [formik.values.quoteProductId]);

  const [suppliers, setVendors] = useState([]);
  const [quotes, setQuotes] = useState([]);

  function getVendors(num) {
    axiosInstance
      .get(`/supplier-quotations/vendor/drop-down/${num}`)
      .then((response) => {
        let tempData = [];
        if (response.data) {
          let x = response.data;

          x.map((item) => {
            let y = {
              value: item.id,
              label: item.name,
            };
            tempData.push(y);
          });

          setVendors(tempData);
        }
      })
      .catch((error) => {});
  }

  useMemo(() => {
    if (formik.values.prId) {
      getVendors(formik.values.prId);
    }
  }, [formik.values.prId]);

  const getQuotes = (prId, vendorId) => {
    if (prId !== "" && vendorId !== "") {
      axiosInstance
        .get(`/supplier-quotations/quotes/drop-down/${prId}/${vendorId}`)
        .then((res) => {
          const data = res.data;
          const rfqOptions = data.map((item) => ({
            value: item.id,
            label: item.name,
          }));
          setQuotes(rfqOptions);
        })
        .catch((err) => {
          console.log(err, "err");
        });
    }
  };

  useEffect(() => {
    if (formik.values.prId && formik.values.vendorId) {
      getQuotes(formik.values.prId, formik.values.vendorId);
    }
  }, [formik.values.prId, formik.values.vendorId]);
  useEffect(() => {
    if (quotes?.length == 1) {
      formik.setFieldValue("sqId", quotes[0].value);
    }
  }, [quotes]);

  const [product, setProducts] = useState([]);

  const [vendorCurrency, setVendorCurrency] = useState("");
  useEffect(() => {
    if (formik.values.sqId) {
      axiosInstance
        .get(`/supplier-quotations/quot-products/po/${formik.values.sqId}`)
        .then((res) => {
          console.log("rrr", res?.data?.data?.products);
          let data = res?.data?.data?.products;
          let tPrice = 0.0;
          for (let i = 0; i < data?.length; i++) {
            tPrice =
              parseFloat(tPrice) +
              parseFloat(data[i].negotiatedPrice) *
                parseFloat(data[i].orderedQty);
          }
          //  setTotalPrice(tPrice);
          formik.setFieldValue("totalPrice", tPrice);
          formik.setFieldValue(
            "paymentTerms",
            res?.data?.data?.paymentTerms || ""
          );
          formik.setFieldValue(
            "deliveryTerms",
            res?.data?.data?.deliveryTerms || ""
          );
          formik.setFieldValue("vendorName", res?.data?.data?.vendorName || "");
          formik.setFieldValue("vendorId", res?.data?.data?.vendorId || "");
          setVendorCurrency(res?.data?.data?.vendorCurrency);

          setProducts(data); // Update products state with fetched data
        })
        .catch((err) => {
          console.log(err, "err");
          // Handle errors here
        });
    }
  }, [formik.values.sqId]);


  useEffect(() => {
    const totalPrice = product?.reduce((total, item) => {
      return (
        total +
        parseFloat(item.negotiatedPrice) * parseFloat(item.orderedQty || 0)
      );
    }, 0);

    formik.setFieldValue("totalPrice", totalPrice);
  }, [product]);

  const handleQuantityChange = (index, neworderedQty) => {
    const updatedItems = [...product];
    updatedItems[index].orderedQty = neworderedQty;
    setProducts(updatedItems);
  };

  const handleIncrement = (index) => {
    handleQuantityChange(index, product[index].orderedQty + 1);
  };

  const handleDecrement = (index) => {
    handleQuantityChange(index, Math.max(1, product[index].orderedQty - 1));
  };

  const handleInputChange = (event, index) => {
    const value = Math.max(Number(event.target.value));
    handleQuantityChange(index, value);
  };

  useEffect(() => {
    if (product?.length > 0) {
      setError("");
    }
  }, [product]);

  const handlenegotiatedPriceChange = (e, index) => {
    const updatedItems = [...product];
    updatedItems[index].negotiatedPrice = e.target.value;
    setProducts(updatedItems);
  };

  const [showPopup, setShowPopup] = useState(false);


  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  const resetForm = () => {
    formik.values.type = ""
    formik.values.companyName = "";
    formik.values.email = "";
    formik.values.phone = "";
    formik.values.countryCode = "";
    formik.values.website = "";
    // industry: "",
    formik.values.customerType = "";
    setShowPopup(false);
  }

  const handlePopupSubmit = async (e) => {
    e.preventDefault();
    

    const formData = new FormData();
    formData.append(
      "agentDetails",
      JSON.stringify({
        type: formik.values.type || "",
        companyName: formik.values.companyName || "",
        email: formik.values.email || "",
        phone: formik.values.phone || "",
        countryCode: formik.values.countryCode || "",
        website: formik.values.website || "",
      })
    );
    try {
      const response = await axiosInstance.post(
        "/agents/new-agent",
        formData
      );
          const createdAgent = response.data?.data;

          setDataCustomer((prevCustomers) => [
            {
              label: formik.values.companyName || "-",
              value: createdAgent
            },...prevCustomers
          ]);
          resetForm();
    } catch (error) {
      console.error("Error creating new customer:", error);
    }
  };

  const handleCheckboxChange = (e) => {
    formik.setFieldValue("bookingEnabled", e.target.checked);
  };

  const [useAddress, setAddress] = useState(false);
  function handleAddress(e) {
    setAddress(e.target.checked);
    if (e.target.checked) {
      formik.values.deliveryAddressLine1 = formik.values.billingAddressLine1;
      formik.values.deliveryAddressLine2 = formik.values.billingAddressLine2;
      formik.values.delivery_country = formik.values.billing_country;
      formik.values.delivery_city = formik.values.billing_city;
      formik.values.delivery_state = formik.values.billing_state;
      formik.values.delivery_postal_code = formik.values.billing_postal_code;
    } else {
      formik.values.deliveryAddressLine1 = "";
      formik.values.deliveryAddressLine2 = "";
      formik.values.delivery_country = "";
      formik.values.delivery_city = "";
      formik.values.delivery_state = "";
      formik.values.delivery_postal_code = "";
    }
  }

  const [address, setAddressdetails] = useState("");

  const getAddress = () => {
    axiosInstance
      .get(`/company-info/address`)
      .then((res) => {
        const data = res?.data?.data;
        setAddressdetails(data); 
        formik.setFieldValue("deliveryAddressLine1", res?.data?.data?.deliveryAddress1 || "");
        formik.setFieldValue("deliveryAddressLine2", res?.data?.data?.deliveryAddress2 || "");
        formik.setFieldValue("delivery_country", res?.data?.data?.delivery_country || "");
        formik.setFieldValue("delivery_city", res?.data?.data?.delivery_city || "");
        formik.setFieldValue("delivery_state", res?.data?.data?.delivery_state || "");
        formik.setFieldValue("delivery_postal_code", res?.data?.data?.delivery_postal_code || "");
        formik.setFieldValue("billingAddressLine1", res?.data?.data?.billingAddress1 || "");
        formik.setFieldValue("billingAddressLine2", res?.data?.data?.billingAddress2 || "");
        formik.setFieldValue("billing_country", res?.data?.data?.billing_country || "");
        formik.setFieldValue("billing_state", res?.data?.data?.billing_state || "");
        formik.setFieldValue("billing_city", res?.data?.data?.billing_city || "");
        formik.setFieldValue("billing_postal_code", res?.data?.data?.billing_postal_code || "");
       
      })
      .catch((err) => {
        console.log("Error fetching address:", err);
      });
  };
  
 
  useEffect(() => {
    getAddress();
  }, []);

  return (
    <div className="w-full h-full py-2 px-4 ">
      <div className="flex justify-between mb-4">
        <Breadcrumb
          items={[
            {
              name: "Purchase Order",
              link: "/procurement/purchase-order",
            },
            { name: "Create" },
          ]}
        />
        <BackButton />
      </div>
      <div className="w-full h-[calc(100vh-140px)] overflow-y-auto">
        <form onSubmit={formik.handleSubmit}>
          <div className="w-full mx-auto pb-4 bg-white rounded-md ">
            <div className=" w-full h-full flex justify-start content-start flex-wrap mb-4">
              <div className="w-full">
                <p className="pb-2 pt-4 px-4 text-sm font-medium text-[#111537] underline decoration-[#048178] decoration-2 underline-offset-4">
                  Purchase Order :
                </p>
              </div>{" "}
              <FormikSelectTwo
                type="text"
                label="YPR"
                placeholder="YPR "
                Options={PR}
                formik={formik}
                name="prId"
                width="w-full md:w-1/2 lg:w-1/3"
                isRequired={true}
                FixPosition={false}
                PortalTarget={false}
              />
          
              <FormikSelectTwo
                type="text"
                label="Supplier "
                placeholder="Supplier "
                Options={suppliers}
                formik={formik}
                name="vendorId"
                width="w-full md:w-1/2 lg:w-1/3"
                isRequired={true}
                FixPosition={false}
                PortalTarget={false}
              />

              {
                formik.values.vendorId && (
                  <FormikSelectTwo
                    type="text"
                    label="Supplier Quote"
                    placeholder="Choose"
                    Options={quotes}
                    formik={formik}
                    name="sqId"
                    isRequired={true}
                    width="w-full md:w-1/2 lg:w-1/3"
                    FixPosition={false}
                    PortalTarget={false}
                  />
                )
              }

              <NuDate
                label="Requested Delivery Date"
                placeholder="dd/mm/yyyy"
                formik={formik}
                name="requestedDeliveryDate"
                width="w-full md:w-1/2 lg:w-1/3"
              />
              <DropDownAddMetaData
                label="Delivery Terms"
                placeholder="Choose"
                Options={DeliveryTerms}
                formik={formik}
                name="deliverTerms"
                width="w-full md:w-1/2 lg:w-1/3"
                getFunc={refDeliveryTerms}
                displayProperty={"Delivery Terms"}
                propertyName={"DeliveryTerms_Supplierqoutes"}
                FixPosition={false}
                PortalTarget={false}
              />
              <DropDownAddMetaData
                label="Payment Status"
                placeholder="Choose"
                Options={paymentStatus}
                formik={formik}
                name="paymentStatus"
                width="w-full md:w-1/2 lg:w-1/3"
                getFunc={refpaymentStatus}
                displayProperty={"Payment Status"}
                propertyName={"paymentStatus_po"}
              />
              <NuTextArea
                label="Comments"
                type="text"
                formik={formik}
                placeholder="Comments"
                name="comments"
                new_width="w-full md:w-2/3"
                width="w-full md:w-1/2 lg:w-1/3"
              />
              
              <div className="flex items-center mt-4 ml-6  w-[30%] text-sm">
                <input
                  type="checkbox"
                  id="bookingEnabled "
                  name="bookingEnabled "
                  checked={formik.values.bookingEnabled}
                  onChange={handleCheckboxChange}
                  className="mr-2 w-4 h-4"
                />
                <label htmlFor="bookingEnabled ">Booking</label>
              </div>
            </div>
          </div>
          {formik.values.bookingEnabled && (
            <>
              <div className="w-full mx-auto pb-4 bg-white rounded-md  mt-4 ">
                <div className=" w-full h-full flex justify-start content-start flex-wrap  mb-4">
                  <div className="w-full">
                    <p className="pb-2 pt-4 px-4 text-sm font-medium text-[#111537] underline decoration-[#048178] decoration-2 underline-offset-4">
                      Booking Details :
                    </p>
                  </div>
                  <NuDate
                    label="Booking Date"
                    placeholder="dd/mm/yyyy"
                    formik={formik}
                    name="bookingDate"
                    isRequired={true}
                    width="w-full md:w-1/2 lg:w-1/3"
                  />
                  <FormikSelectWithAdd
                    type="text"
                    label="Agent "
                    placeholder="Agent  "
                    Options={Agents}
                    formik={formik}
                    name="agent"
                    isRequired={true}
                    width="w-full md:w-1/2 lg:w-1/3"
                    px="0"
                    handleClick={togglePopup}
                    FixPosition={false}
                    PortalTarget={false}
                    setSearch={setSearchAgent}
                  />

                  <DropDownAddMetaData
                    label="Mode Of Shipment"
                    placeholder="Choose"
                    Options={ShippingMethod}
                    formik={formik}
                    name="modeOfShipment"
                    width="w-full md:w-1/2 lg:w-1/3"
                    getFunc={refShippingMethod}
                    displayProperty={"Mode Of Shipment"}
                    propertyName={"ShippingMethod"}
                  />

                  <FormikSelectTwo
                    type="text"
                    label="Permit Required?"
                    placeholder="Permit Required?"
                    Options={[
                      { value: false, label: "No" },
                      { value: true, label: "Yes" },
                    ]}
                    formik={formik}
                    name="permitReq"
                    width="w-full md:w-1/2 lg:w-1/3"
                    FixPosition={false}
                    PortalTarget={false}
                  />

                  <NuDate
                    label="ETD"
                    placeholder="dd/mm/yyyy"
                    formik={formik}
                    name="ETD"
                    width="w-full md:w-1/2 lg:w-1/3"
                  />

                  <NuDate
                    label="ETA"
                    placeholder="dd/mm/yyyy"
                    formik={formik}
                    name="ETA"
                    width="w-full md:w-1/2 lg:w-1/3"
                  />
                  <NuInput
                    type="number"
                    label="Estimated Shipping Cost"
                    placeholder="Estimated Shipping Cost"
                    formik={formik}
                    name="ESC"
                    width="w-full md:w-1/2 lg:w-1/3"
                  />
                  <NuInput
                    type="text"
                    label="Actual Shipping Cost"
                    placeholder="Actual Shipping Cost"
                    formik={formik}
                    name="ASC"
                    width="w-full md:w-1/2 lg:w-1/3"
                  />

                  <NuInput
                    type="text"
                    label="Shipment Bill Number"
                    placeholder="Shipment Bill Number"
                    formik={formik}
                    name="shipmentBill"
                    width="w-full md:w-1/2 lg:w-1/3"
                  />
                  <NuInput
                    type="text"
                    label="Tracking Url"
                    placeholder="Tracking Url"
                    formik={formik}
                    name="trackingUrl"
                    width="w-full md:w-1/2 lg:w-2/3"
                  />
                </div>
              </div>
            </>
          )}

          <div className="w-full mx-auto pb-4 bg-white rounded-md mt-4 ">
            <div className=" w-full h-full flex justify-start content-start flex-wrap mb-4">
              <div className="w-full">
                <p className="pb-2 pt-4 px-4 text-sm font-medium text-[#111537] underline decoration-[#048178] decoration-2 underline-offset-4">
                  Billing Address :
                </p>
              </div>

              <NuInput
                type="text"
                label="Street Address Line 1"
                placeholder="Street Address Line 1"
                formik={formik}
                name="billingAddressLine1"
                width="w-full md:w-1/2 lg:w-1/3"
              />

              <NuInput
                type="text"
                label="Street Address Line 2"
                placeholder="Street Address Line 2"
                formik={formik}
                name="billingAddressLine2"
                width="w-full md:w-1/2 lg:w-1/3"
              />

              <NuInput
                type="text"
                label="City"
                placeholder="City"
                formik={formik}
                name="billing_city"
                width="w-full md:w-1/2 lg:w-1/3"
              />

              <NuInput
                type="text"
                label="State / Province"
                placeholder="State / Province"
                formik={formik}
                name="billing_state"
                width="w-full md:w-1/2 lg:w-1/3"
              />

              <NuInput
                type="number"
                label="Postal / Zip Code"
                placeholder="Postal / Zip Code"
                formik={formik}
                name="billing_postal_code"
                width="w-full md:w-1/2 lg:w-1/3"
              />

              <FormikSelectTwo
                type="text"
                label="Country"
                placeholder="Country"
                Options={CountryMIN}
                formik={formik}
                name="billing_country"
                width="w-full md:w-1/2 lg:w-1/3"
                FixPosition={false}
                PortalTarget={false}
              />
            </div>
            <div className=" px-4 pb-2 flex gap-1">
              <input
                type="checkbox"
                checked={useAddress}
                onChange={handleAddress}
              />
              <label className=" text-xs">
                Use Billing Address as Shipping Address
              </label>
            </div>
          </div>

          <div className="w-full mx-auto pb-4 bg-white rounded-md mt-4 ">
            <div className=" w-full h-full flex justify-start content-start flex-wrap mb-4">
              <div className="w-full">
                <p className="pb-2 pt-4 px-4 text-sm font-medium text-[#111537] underline decoration-[#048178] decoration-2 underline-offset-4">
                  Delivery Address :
                </p>
              </div>

              <NuInput
                type="text"
                label="Street Address Line 1"
                placeholder="Street Address Line 1"
                formik={formik}
                name="deliveryAddressLine1"
                width="w-full md:w-1/2 lg:w-1/3"
              />

              <NuInput
                type="text"
                label="Street Address Line 2"
                placeholder="Street Address Line 2"
                formik={formik}
                name="deliveryAddressLine2"
                width="w-full md:w-1/2 lg:w-1/3"
              />

              <NuInput
                type="text"
                label="City"
                placeholder="City"
                formik={formik}
                name="delivery_city"
                width="w-full md:w-1/2 lg:w-1/3"
              />

              <NuInput
                type="text"
                label="State / Province"
                placeholder="State / Province"
                formik={formik}
                name="delivery_state"
                width="w-full md:w-1/2 lg:w-1/3"
              />

              <NuInput
                type="number"
                label="Postal / Zip Code"
                placeholder="Postal / Zip Code"
                formik={formik}
                name="delivery_postal_code"
                width="w-full md:w-1/2 lg:w-1/3"
              />

              <FormikSelectTwo
                type="text"
                label="Country"
                placeholder="Country"
                Options={CountryMIN}
                formik={formik}
                name="delivery_country"
                width="w-full md:w-1/2 lg:w-1/3"
                FixPosition={false}
                PortalTarget={false}
              />
            </div>
          </div>

          <div className="w-full mx-auto h-auto bg-white rounded-md overflow-hidden mt-4 ">
            <div className=" w-full h-full flex justify-start content-start flex-wrap  overflow-y-auto mb-4">
              <div className="w-full">
                <p className="pb-2 pt-4 px-4 text-sm font-medium text-[#111537] underline decoration-[#048178] decoration-2 underline-offset-4">
                  Products :
                </p>
              </div>

              {/* <div className="w-full h-0.5 opacity-20 bg-[#C4C2C2] my-6 mx-2"></div> */}

              <div className="w-full bg-white overflow-y-scroll mt-5">
                {error && (
                  <p className="text-xs font-semibold text-red-500">{error}</p>
                )}
                <div className="w-full py-3  bg-[#048178] flex justify-between rounded-lg px-4">
                  <p className="w-[5%]  text-left  text-white text-xs font-medium">
                    S.N.
                  </p>

                  <p className=" w-[40%] text-left text-white text-xs font-medium">
                    Product
                  </p>
                  <p className=" w-[10%] text-center text-white text-xs font-medium">
                    Unit
                  </p>
                  <p className=" w-[15%] text-center text-white text-xs font-medium">
                    Req.Qty
                  </p>
                  <p className=" w-[20%] text-center text-white text-xs font-medium">
                    Ordered Qty
                  </p>
                  <p className=" w-[15%] text-center text-white text-xs font-medium">
                    Price
                  </p>
                  <p className=" w-[15%] text-center text-white text-xs font-medium">
                    Neg.Price
                  </p>
                  <p className=" w-[10%] text-center text-white text-xs font-medium">
                    Total
                  </p>
                  <p className=" w-[5%] text-center text-white text-xs font-medium">
                    Action
                  </p>
                </div>
                {product?.length > 0 ? (
                  <div className="h-[calc(100vh-300px)] overflow-y-scroll">
                    {product?.map((List, index) => {
                      return (
                        <div
                          key={index}
                          className="w-full py-2 flex justify-between px-6 odd:bg-white even:bg-slate-50"
                        >
                          <p className="w-[5%]  text-left   text-xs font-medium text-[#111537] py-1">
                            {index + 1}
                          </p>
                          <p className=" w-[40%] text-left  text-xs font-medium text-[#111537]">
                            {List?.productName}
                          </p>
                          <p className=" w-[10%] text-center  text-xs font-medium text-[#111537]">
                            {List?.unit}
                          </p>
                          <p className=" w-[15%] text-center  text-xs font-medium text-[#111537]">
                            {List?.requestedQty}
                          </p>
                          <div className="w-[20%] text-center  text-xs font-medium text-[#111537]">
                            <button
                              type="button"
                              className="border rounded-full p-1 "
                              onClick={() => handleDecrement(index)}
                            >
                              -
                            </button>
                            <input
                              type="number"
                              className="w-[20%] text-center border  text-xs font-medium text-[#111537]"
                              name="orderedQty"
                              value={List.orderedQty}
                              onChange={(e) => handleInputChange(e, index)}
                            />
                            <button
                              type="button"
                              className="border rounded-full p-1"
                              onClick={() => handleIncrement(index)}
                            >
                              +
                            </button>
                          </div>
                          <p className=" w-[15%] text-center  text-xs font-medium text-[#111537]">
                            {List?.price}
                          </p>
                          <div className="w-[15%] px-1">
                            <input
                              type="number"
                              className="w-[90%] py-1 text-center border  text-xs font-medium text-[#111537]"
                              name="negotiatedPrice"
                              formik={formik}
                              placeholder="Enter the Negotiated Price"
                              value={List.negotiatedPrice || ""}
                              onChange={(e) =>
                                handlenegotiatedPriceChange(e, index)
                              }
                            />
                          </div>
                          <p className="text-center w-[10%]  text-xs font-medium text-[#111537]">
                            {(
                              Number(List?.negotiatedPrice) *
                                Number(List?.orderedQty) || 0
                            ).toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          </p>

                          <div className="w-[5%] text-center">
                            <button
                              onClick={() => handleDeleteItem(index)}
                              type="button"
                              className="text-red-500 cursor-pointer  ml-4 border-none"
                            >
                              <Delete color="red" height={4} />
                            </button>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <div className="w-full text-center text-[#93949f]">
                    No Items here
                  </div>
                )}
              </div>
              <div className="w-full my-6 flex justify-end items-center gap-4">
                <TextMediumBase content={"Total Price : "} />
                <p className="text-sm">{vendorCurrency}</p>
                <p className="w-64 h-8 border rounded-md text-xs pl-2 py-2">
                  <NuAmount
                    // currency={vendorCurrency}
                    defaultCurrency={false}
                    value={formik?.values?.totalPrice || 0}
                  />
                </p>
              </div>
              <div className="w-full hidden md:block mx-auto my-4 mt-8 ">
                <div className="md:flex justify-end gap-4">
                  <CancelButton
                    handleClick={() => {
                      Oncancel(-1);
                    }}
                  />
                  <SubmitButton isLoading={isLoading} />
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      {showPopup && (
        <div className="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-50 z-[9999] flex justify-center items-center">
          <div className="animate-scale-in w-[60%] max-h-[80vh] bg-white py-2 px-4 rounded-md shadow-lg overflow-y-scroll ">
            <h2 className="text-lg font-normal text-[12px]">Add Customer</h2>
            <form onSubmit={handlePopupSubmit}>
              <div className="mb-4 flex flex-wrap">
                <DropDownAddMetaData
                  label="Agent Type"
                  placeholder="Choose"
                  Options={Type}
                  formik={formik}
                  name="type"
                  width="w-full md:w-1/2 lg:w-1/2"
                  getFunc={refType}
                  displayProperty={"Agent Type"}
                  propertyName={"AgentType"}
                />
                <NuInput
                  type="text"
                  label="Company Name"
                  placeholder="Company Name"
                  formik={formik}
                  name="companyName"
                  width="w-full md:w-1/2 lg:w-1/2"
                  isRequired={true}
                />
                <NuMobileNumber
                  nunCode="countryCode"
                  label="Phone"
                  placeholder="Phone"
                  formik={formik}
                  name="phone"
                  width="w-full md:w-1/2 lg:w-1/2"
                  FixPosition={true}
                  isRequired={true}
                />
                <NuInput
                  type="email"
                  label="Email"
                  placeholder="Email"
                  formik={formik}
                  name="email"
                  width="w-full md:w-1/2 lg:w-1/2"
                  isRequired={true}
                />
                <NuInput
                  type="text"
                  label="Website"
                  placeholder="website"
                  formik={formik}
                  name="website"
                  width="w-full md:w-1/2 lg:w-1/2"
                />
              </div>
              <div className="flex justify-end">
                <button
                  type="button"
                  onClick={togglePopup}
                  className="px-5 py-[9px] cursor-pointer text-xs 2xl:text-sm bg-[#E6F2F2]  hover:bg-gray-300
                  rounded-md flex items-center mr-2 text-textColor-main"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="bg-[#048178] px-5 py-[9px] cursor-pointer outline-none border-none text-white rounded-md text-xs 2xl:text-sm flex items-center"
                >
                  Add
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}
export default CreatePo;
